import api from '.';

const URL = process.env.REACT_APP_API_URL;
const REPORTS_URL = `${URL}/mbadmin/reports`;

const getActivityReport = (dispatch, groupId, eventId) => {
  return api.adminApi(dispatch, 'get', `${REPORTS_URL}/${groupId}/activity`, null, { eventId })
}

const getSummaryReport = (dispatch, groupId) => {
  return api.adminApi(dispatch, 'get', `${REPORTS_URL}/${groupId}/summary`)
}

const getUsersReport = (dispatch) => {
  return api.adminApi(dispatch, 'get', `${REPORTS_URL}/users`)
}

export default {
  getActivityReport,
  getSummaryReport,
  getUsersReport
}
