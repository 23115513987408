import api from '.';

const URL = process.env.REACT_APP_API_URL;


const getImageUploadToken = async (dispatch) => {
  return api.adminApi(dispatch, 'get', `${URL}/mbadmin/image-upload-token`);
};

export default {
  getImageUploadToken
};
