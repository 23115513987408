import api from '.';

const URL = process.env.REACT_APP_API_URL;
const GROUP_MEMBER_URL = `${URL}/mbadmin/group-member`; 

const createGroupMember = async (dispatch, data) => {
  return api.adminApi(dispatch, 'post', GROUP_MEMBER_URL, data);
};

const getGroupMembers = async (dispatch, groupId) => {
  return api.adminApi(dispatch, 'get', `${GROUP_MEMBER_URL}/${groupId}`);
}

const updateGroupMemberOwners = async (dispatch, members) => {
  return api.adminApi(dispatch, 'put', GROUP_MEMBER_URL, {members});
}

const removeGroupMember = async (dispatch, id) => {
  return api.adminApi(dispatch, 'delete', `${GROUP_MEMBER_URL}/${id}`);
}

export default {
  createGroupMember,
  getGroupMembers,
  updateGroupMemberOwners,
  removeGroupMember,
};
