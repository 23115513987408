import { SET_AUTH_USER } from "../types";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        userInfo: action.payload,
      };
    default:
      return state;
  }
};
