import {
    GET_EVENTS,
    GET_EVENT,
    UPDATE_EVENT,
    DELETE_EVENT,
    CLONE_EVENT
} from '../types';

export default (state = {}, action) => {
    switch (action.type) {
        case GET_EVENTS:
        return {
            ...state,
            events: action.payload,
        };
        case GET_EVENT:
        return {
            ...state,
            event: action.payload,
        };
        case UPDATE_EVENT:
        return {
            ...state,
            event: action.payload.updated,
        };
        case DELETE_EVENT:
        return {
            ...state,
            event: null,
        };
        case CLONE_EVENT:
          return {
            ...state,
            clone: action.payload
          }
        default:
        return state;
    }
};
  