import semesterApi from "../../api/semester.api"
import { SET_SEMESTER_DATA } from "../types";


export const getSemesterDataAction = () => async (dispatch) => {
  const semester = await semesterApi.getSemesterData(dispatch);
  dispatch({
    type: SET_SEMESTER_DATA,
    payload: semester
  })
}

export const saveSemesterDataAction = (data) => async (dispatch) => {
  const semester = await semesterApi.updateSemesterData(dispatch, data);
  dispatch({
    type: SET_SEMESTER_DATA,
    payload: semester
  })
}
