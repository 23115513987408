import imageUploadApi from '../../api/imageUpload.api';

import {
  GET_IMAGE_UPLOAD_TOKEN
} from '../types';


export const getImageUploadTokenAction = () => async (dispatch) => {
  const imageUploadToken = await imageUploadApi.getImageUploadToken(dispatch);
  dispatch({
    type: GET_IMAGE_UPLOAD_TOKEN,
    payload: imageUploadToken,
  });
  return imageUploadToken;
};