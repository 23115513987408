import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { FilterList, Cancel, Close } from '@material-ui/icons';

const FilterModal = (props) => {
  const { onSave, itemName, filterDisplay, resetFilters, children, disabled = false } = props;
  const [show, setShow] = useState(false);

  const onSaveWrapper = async () => {
    await onSave();
    setShow(false);
  };
  const onClose = () => setShow(false);
  return (
    <>
      {!!filterDisplay && (
        <span style={{ fontSize: '0.75rem' }}>{filterDisplay}</span>
      )}
      <IconButton onClick={() => setShow(true)}>
        <FilterList color="primary" />
      </IconButton>
      {resetFilters && filterDisplay && (
        <Cancel
          style={{ cursor: 'pointer' }}
          color="error"
          onClick={resetFilters}
        />
      )}
      <Dialog open={show} onClose={onClose}>
        <DialogTitle disableTypography className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            {`Filter ${itemName}`}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            disabled={disabled}
            onClick={onSaveWrapper}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilterModal;
