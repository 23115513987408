import { Button, Grid, LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getSemesterDataAction, saveSemesterDataAction } from '../store/actions/semester.actions';

const Semester = () => {
  const [pointsStartDate, setPointsStartDate] = useState(new Date(2021, 0, 1));
  const [isLoading, setIsLoading] = useState(false);

  const semester = useSelector((state) => state.semester.semester);

  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(getSemesterDataAction());
      setIsLoading(false);
    }
    if (!semester) {
      load();
    } else {
      setPointsStartDate(semester.pointsStartDate)
    }
  }, [semester])

  const saveSemester = async () => {
    setIsLoading(true);
    await dispatch(saveSemesterDataAction({ pointsStartDate }));
    setIsLoading(false);
  }

  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }

  return (
    <Grid className="semester">
      <Grid item sm={3} />
      <Grid item sm={6}>
        <h2>Semester Data</h2>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            fullWidth
            variant="inline"
            className="mb-4"
            ampm={true}
            label="Points Start Counting From"
            value={pointsStartDate}
            onChange={setPointsStartDate}
            onError={console.log}
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <div className="mt-4 text-right">
          <Button variant="contained" color="primary" onClick={saveSemester}>
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default Semester;
