import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    TextField,
    Button,
    Grid,
    LinearProgress,
    Tooltip,
    Typography
  } from '@material-ui/core';
import { CloudUpload } from "@material-ui/icons";
import { BlockBlobClient } from '@azure/storage-blob'

import {
    updateGroupAction,
    createGroupAction,
    deleteGroupAction,
} from '../../store/actions/group.actions';
import {
    getImageUploadTokenAction
} from '../../store/actions/imageUpload.actions';
import ConfirmationModal from '../../components/ConfirmationModal';

const GroupTab = ({
    group,
    isCreating,
    setIsLoading
  }) => {
    const history = useHistory();
    const dispatch = useDispatch();
  
    const [name, setName] = useState(group?.name ?? '');
    const [description, setDescription] = useState(group?.description ?? '');
    const [headerPhotoUrl, setHeaderPhotoUrl] = useState(group?.headerPhotoUrl ?? '');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  
    const [imageUploadError, setImageUploadError] = useState('');
    const [isImageUploading, setIsImageUploading] = useState(false);
  
    const saveGroup = async () => {
      const data = {
        name,
        description,
        headerPhotoUrl
      };
      setIsLoading(true);
      let result = null;
      if (isCreating) {
        result = await dispatch(createGroupAction(data));
      } else {
        result = await dispatch(updateGroupAction(data, group.id));
      }
  
      setIsLoading(false);
      if (result) {
        history.push('/groups');
      }
    };
  
    const deleteGroup = () => {
      dispatch(deleteGroupAction(group.id));
      history.push('/groups');
    };
  
    const handleFileSelected = async ({ target }) => {
      try {
        setIsImageUploading(true);
        const imageUploadToken = await dispatch(getImageUploadTokenAction());
        if (!imageUploadToken) {
          setImageUploadError('Error uploading image');
        } else {
          const blobClient = new BlockBlobClient(imageUploadToken.uri);
          await blobClient.uploadBrowserData(target.files[0]);
          setHeaderPhotoUrl(imageUploadToken.cdnUri);
          setImageUploadError('');
        }
      } catch (err) {
        setImageUploadError('Error uploading image');
      } finally {
        target.value = null;
        setIsImageUploading(false);
      }
  
    };
  
    return (
      <>
        <Grid item sm={3} />
        <Grid item sm={6}>
            <h2>Group</h2>
            <TextField
              id="group-name"
              label="Name"
              type="string"
              className="mb-4"
              value={name}
              fullWidth
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <TextField
              id="group-description"
              label="Description"
              type="text"
              className="mb-4"
              multiline
              rows={10}
              value={description}
              fullWidth
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
            {headerPhotoUrl && <img style={{ maxWidth: 150 }} src={headerPhotoUrl} alt="Group Header" />}
            <Typography variant="subtitle1">Header Photo</Typography>
            <input
              accept="image/jpeg"
              style={{ display: 'none' }}
              id="headerPhoto"
              type="file"
              onChange={handleFileSelected}
            />
  
            <Tooltip title="Select Image">
              <label htmlFor="headerPhoto">
                <Button
                  variant="contained"
                  disabled={isImageUploading}
                  color="primary"
                  aria-label="upload header photo"
                  component="span"
                  startIcon={<CloudUpload />}>
                  Upload Image
                </Button>
              </label>
            </Tooltip>
            {isImageUploading && <LinearProgress style={{ height: '1rem' }} color="secondary" />}
            {imageUploadError && <Typography variant="body2" color="error">{imageUploadError}</Typography>}
            <div className="mt-4 text-right">
              <Button variant="contained" color="primary" onClick={saveGroup}>
                Save
              </Button>
              {!isCreating && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="ml-2"
                  disabled={isImageUploading}
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Delete
                </Button>
              )}
            </div>
          </Grid>
          <ConfirmationModal
            show={showConfirmationModal}
            onConfirm={deleteGroup}
            onCancel={() => setShowConfirmationModal(false)}
            headerText="Delete Group"
            bodyText="Are you sure you want to delete this group?"
          />
      </>
    );
}

export default GroupTab;