import api from '.';

const URL = process.env.REACT_APP_API_URL;
const GROUP_URL = `${URL}/mbadmin/group`;

const createGroup = async (dispatch, data) => {
  return api.adminApi(dispatch, 'post', GROUP_URL, data);
};

const getGroups = async (dispatch, params) => {
  return api.adminApi(dispatch, 'get', `${GROUP_URL}`, null, params);
};

const getGroup = async (dispatch, id) => {
  return api.adminApi(dispatch, 'get', `${GROUP_URL}/${id}`);
};

const updateGroup = async (dispatch, event, id) => {
  return api.adminApi(dispatch, 'put', `${GROUP_URL}/${id}`, event);
};

const deleteGroup = async (dispatch, id) => {
  return api.adminApi(dispatch, 'delete', `${GROUP_URL}/${id}`);
};

const getGroupInviteCodes = async (dispatch, groupId, params) => {
  return api.adminApi(dispatch, 'get', `${GROUP_URL}/${groupId}/invite-codes`, null, params);
};

const createGroupInviteCode = async (dispatch, groupId) => {
  return api.adminApi(dispatch, 'post', `${GROUP_URL}/${groupId}/invite-code`);
};

const updateGroupInviteCode = async (dispatch, groupId, inviteCode) => {
  return api.adminApi(dispatch, 'put', `${GROUP_URL}/${groupId}/invite-code/${inviteCode.id}`, inviteCode);
};

const bulkAttendEvents = async (dispatch, groupId, bonusPointData) => {
  return api.adminApi(dispatch, 'post', `${GROUP_URL}/${groupId}/event-attendance`, bonusPointData)
}

export default {
  createGroup,
  getGroups,
  getGroup,
  updateGroup,
  deleteGroup,
  getGroupInviteCodes,
  createGroupInviteCode,
  updateGroupInviteCode,
  bulkAttendEvents
};
