import api from '.';

const URL = process.env.REACT_APP_API_URL;
const EVENT_URL = `${URL}/mbadmin/event`;

const createEvent = async (dispatch, data) => {
  return api.adminApi(dispatch, 'post', EVENT_URL, data);
};

const getEvents = async (dispatch, params) => {
  return api.adminApi(dispatch, 'get', `${EVENT_URL}`, null, params);
};

const getEvent = async (dispatch, id) => {
  return api.adminApi(dispatch, 'get', `${EVENT_URL}/${id}`);
};

const updateEvent = async (dispatch, event, id) => {
  return api.adminApi(dispatch, 'put', `${EVENT_URL}/${id}`, event);
};

const deleteEvent = async (dispatch, id) => {
  return api.adminApi(dispatch, 'delete', `${EVENT_URL}/${id}`);
};

const cloneEvent = async (dispatch, id, groupId) => {
  return api.adminApi(dispatch, 'post', `${EVENT_URL}/${id}/clone`, {groupId});
};

const bulkImportEvents = async (dispatch, groupId, events) => {
  return api.adminApi(dispatch, 'post', `${EVENT_URL}/${groupId}`, {events})
}


export default {
  createEvent,
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  cloneEvent,
  bulkImportEvents
};
