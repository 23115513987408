import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Delete, Cancel, Sync } from '@material-ui/icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableBody,
  TablePagination,
  Paper,
  Button,
  Table,
  IconButton,
  TextField,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import {
  getUsersAction,
  deleteUserAction,
  syncUsersAction,
} from '../store/actions/user.actions';
import ConfirmationModal from '../components/ConfirmationModal';
import FilterModal from '../components/Filter';
import { PAGINATION_OPTIONS } from '../constants';

import './Users.scss';

const USER_FIELDS = [
  { display: 'oktaUserId', render: (u) => u.oktaUserId},
  { display: 'name', render: (u) => `${u.firstName} ${u.lastName}`},
  { display: 'uin', render: (u) => u.uin},
  { display: 'userType', render: (u) => u.userType},
  { display: 'email', render: (u) => u.email},
  { display: 'isDeleted', render: (u) => `${u.isDeleted}`},
  { display: 'createdBy', render: (u) => u.createdBy},
  { display: 'createdDate', render: (u) => moment(u.createdDate).format('ll')},
  { display: 'major', render: (u) => u.major},
  { display: 'college', render: (u) => u.college},
  { display: 'graduationSemester', render: (u) => u.graduationSemester},
  { display: 'graduationYear', render: (u) => u.graduationYear},
  { display: 'exclude', render: (u) => u.exclude.toString()},
  { display: 'notes', render: (u) => u.notes},
];

const Users = () => {
  const users = useSelector((state) => state.user.users);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [filter, setFilter] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [currentFilter, setCurrentFilter] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('createdDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isSyncingUsers, setIsSyncingUsers] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);
      await dispatch(getUsersAction());
      setIsLoading(false);
    };
    loadUsers();
  }, [dispatch]);

  const getUsers = (newCountPerPage, newPageNumber) => {
    let ordering;
    if (sortField) {
      ordering = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(
      getUsersAction({
        filter: currentFilter,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
        ordering,
      })
    );
  };
  const goToUser = (id) => {
    history.push(`/users/${id}`);
  };
  const deleteUser = async () => {
    setIsLoading(true);
    setShowConfirmationModal(false);
    await dispatch(deleteUserAction(userIdToDelete));
    await dispatch(getUsersAction());
    setIsLoading(false);
  };
  const openConfirmDelete = (e, id) => {
    e.stopPropagation();
    setUserIdToDelete(id);
    setShowConfirmationModal(true);
  };
  const saveFilters = async () => {
    setCurrentFilter(filter);
    let sort;
    if (sortField) {
      sort = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(getUsersAction({ filter, sort }));
  };
  const resetFilters = () => {
    setFilter({
      firstName: '',
      lastName: '',
      email: ''
    });
    setCurrentFilter({});
    dispatch(getUsersAction());
  };
  const onFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    });
  }
  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getUsers(countPerPage, newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    const newPageNum = 0;
    setPageNumber(newPageNum)
    getUsers(newCountPerPage, newPageNum);
  };
  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
    let ordering;
    if (field) {
      ordering = `${
        newSortDirection === 'desc' ? encodeURIComponent('-') : ''
      }${field}`;
    }
    dispatch(
      getUsersAction({
        filter: currentFilter,
        ordering,
      })
    );
  };
  const runUserSync = async () => {
    setIsSyncingUsers(true);
    await dispatch(syncUsersAction());
    await dispatch(getUsersAction());
    setIsSyncingUsers(false);
  };

  return (
    <div className="users">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Users</span>
        <Button
          variant="outlined"
          color="primary"
          className="mx-2 px-1"
          onClick={runUserSync}
        >
          {isSyncingUsers ? <CircularProgress color="secondary" /> : <Sync />}
          Run Sync
        </Button>
        <div className="flex-grow-1" />
        {(filter.firstName || filter.lastName || filter.email) && (
          <IconButton onClick={resetFilters}>
            <Cancel color="error" />
          </IconButton>
        )}
        <FilterModal onSave={saveFilters} itemName="Users">
          <div>
            <TextField
              id="filter-firstName"
              label="First Name"
              placeholder="Enter a First Name"
              value={filter.firstName}
              onChange={(e) => onFilterChange('firstName', e.currentTarget.value)}
            />
          </div>
          <div>
            <TextField
              id="filter-lastName"
              label="Last Name"
              placeholder="Enter a Last Name"
              value={filter.lastName}
              onChange={(e) => onFilterChange('lastName', e.currentTarget.value)}
            />
          </div>
          <div>
            <TextField
              id="filter-email"
              label="Email"
              placeholder="Enter an email address"
              value={filter.email}
              onChange={(e) => onFilterChange('email', e.currentTarget.value)}
            />
          </div>
        </FilterModal>
      </h2>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!users && (
        <>
          <TableContainer component={Paper}>
            <Table size="small" padding="none">
              <TableHead className="bg-dark">
                <TableRow>
                  {USER_FIELDS.map((field) => {
                    return (
                      <TableCell key={field.display} align="center">
                        <TableSortLabel
                          active={field.sortField === sortField}
                          className="text-white"
                          direction={
                            field.sortField === sortField
                              ? sortDirection
                              : 'desc'
                          }
                          hideSortIcon={!field.sortField}
                          onClick={() => updateSort(field.sortField)}
                        >
                          {field.display}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.data.map((user) => (
                  <TableRow
                    key={user._id}
                    hover
                    className="cursor-pointer"
                    onClick={() => goToUser(user.oktaUserId)}
                  >
                    {USER_FIELDS.map((field) => (
                      <TableCell style={{paddingLeft: "5px"}} key={field.display} align="left">
                        {field.render(user)}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <IconButton
                        onClick={(e) => openConfirmDelete(e, user._id)}
                        disabled={true}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={countPerPage}
            component="div"
            count={users.totalCount}
            page={pageNumber}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={deleteUser}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete User"
        bodyText="Are you sure you want to delete this user?"
      />
    </div>
  );
};

export default Users;
