import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react'
import { jsonToCSV } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import reportsApi from '../api/reports.api';

const Reports = () => {
    const [reportType, setReportType] = useState('users');
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);

    const dispatch = useDispatch();
  
    const handleChange = (e) => {
      setReportType(e.target.value);
    };

    const generateReport = async () => {
        setIsGeneratingReport(true);
        try {
            let response;
            switch (reportType) {
                case 'users':
                    response = await reportsApi.getUsersReport();
                    break;
            }
            const csvData = jsonToCSV(response, { quotes: true });
            const encodedUri = `data:text/csv;charset=UTF-8\uFEFF,${encodeURIComponent(csvData)}`;
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${reportType} report.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); 
        } catch (err) {
            console.error('unable to generate report', err);
        }
  
        setIsGeneratingReport(false);
    };
    return (
        <div className='reports'>
            <Grid item sm={3} />
            <Grid item sm={6}>
                <h2>Reporting</h2>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="report-type-label">Report Type</InputLabel>
                        <Select
                            labelId="report-type-label"
                            id="report-type"
                            value={reportType}
                            onChange={handleChange}
                            fullWidth
                        >
                            <MenuItem value={'users'}>Users</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="mt-4">
                    <Button color="primary" variant="contained" onClick={generateReport}>
                    {isGeneratingReport ? <CircularProgress size={30} color="secondary" /> : "Download"}
                    </Button>
                </div>
            </Grid>
        </div>
    )
}

export default Reports
