import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';

import { triggerPasswordResetAction, updateUserAction } from '../../store/actions/user.actions';

const INFO_MESSAGE_FADE_TIME = 3000;

const MAX_GRADUATION_YEAR = ( new Date() ).getFullYear() + 10;
const MIN_GRADUATION_YEAR = ( new Date() ).getFullYear() - 40;

const MAX_SEMESTER = 3;
const MIN_SEMESTER = 1;

const _cleanInputInt = (value) => {
  if (value === '') {
    return 0;
  }

  let new_value = `${ value }`.trim();
  new_value = new_value.replace( /[^0-9.]/g, '' );
  new_value = new_value.replace( /\.(?=.*\.)/, '' );
  new_value = parseFloat( new_value, 10 );
  new_value = Math.abs( new_value );

  return parseInt(value, 10);
};

const UserTab = (props) => {
  const {user, groupId} = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [uin, setUin] = useState('');
  const [userType, setUserType] = useState('');
  const [major, setMajor] = useState('');
  const [college, setCollege] = useState('');
  const [graduationSemester, setGraduationSemester] = useState('');
  const [graduationYear, setGraduationYear] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [exclude, setExclude] = useState(false);
  const [notes, setNotes] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setUin(user.uin);
      setUserType(user.userType);
      setMajor(user.major);
      setCollege(user.college);
      setGraduationSemester(user.graduationSemester || '');
      setGraduationYear(user.graduationYear || '');
      setExclude(user.exclude);
      setNotes(user.notes);
    }
  }, [user]);

  const triggerUpdateUser = useCallback(async () => {
    const updatedUser = {
      ...user,
      firstName,
      lastName,
      uin,
      userType,
      major,
      college,
      graduationSemester,
      graduationYear,
      exclude,
      notes,
    };

    await dispatch( updateUserAction(updatedUser, user.id) );

    setInfoMessage('User updated');
    setTimeout(
      () => setInfoMessage(''),
      INFO_MESSAGE_FADE_TIME
    );
  }, [ user, firstName, lastName, uin, userType, major, college, graduationSemester, graduationYear, exclude, notes ]);
  
  const triggerPasswordReset = async () => {
    await dispatch(triggerPasswordResetAction(user.email));
    setInfoMessage('An SMS has been sent to the user');
    setTimeout(
      () => setInfoMessage(''),
      INFO_MESSAGE_FADE_TIME
    );
  };

  const handleSemesterBlur = React.useCallback(() => {
    if ( graduationSemester == '' ) {
      return;
    }

    setGraduationSemester( Math.max( MIN_SEMESTER, Math.min( MAX_SEMESTER, _cleanInputInt( graduationSemester ) ) ) );
  }, [ graduationSemester ]);

  const handleSemesterChange = (e) => {
    setGraduationSemester( _cleanInputInt( e.currentTarget.value ) );
  };

  const handleYearBlur = React.useCallback((e) => {
    if ( graduationYear == '' ) {
      return;
    }

    setGraduationYear( Math.max( MIN_GRADUATION_YEAR, Math.min( MAX_GRADUATION_YEAR, _cleanInputInt( graduationYear ) ) ) );
  }, [ graduationYear ]);

  const handleYearChange = (e) => {
    setGraduationYear( _cleanInputInt( e.currentTarget.value ) );
  };

  return (
    <div className="user-tab">
    <h2>User</h2>
    <TextField
      id="user-email"
      label="Email"
      type="email"
      className="mb-4"
      value={email}
      fullWidth
      disabled={true}
    />
    <TextField
      id="user-first-name"
      label="First name"
      type="string"
      className="mb-4"
      value={firstName}
      fullWidth
      onChange={(e) => setFirstName(e.currentTarget.value)}
    />
    <TextField
      id="user-last-name"
      label="Last name"
      type="string"
      className="mb-4"
      value={lastName}
      fullWidth
      onChange={(e) => setLastName(e.currentTarget.value)}
    />
    {!groupId && (<TextField
      id="user-uin"
      label="UIN"
      type="string"
      className="mb-4"
      value={uin}
      fullWidth
      onChange={(e) => setUin(e.currentTarget.value)}
    />)}
    <TextField
      id="user-type"
      label="User Type"
      type="string"
      className="mb-4"
      value={userType}
      fullWidth
      onChange={(e) => setUserType(e.currentTarget.value)}
    />
    <TextField
      id="user-major"
      label="Major"
      type="string"
      className="mb-4"
      value={major}
      fullWidth
      onChange={(e) => setMajor(e.currentTarget.value)}
    />
    <TextField
      id="user-college"
      label="College"
      type="string"
      className="mb-4"
      value={college}
      fullWidth
      onChange={(e) => setCollege(e.currentTarget.value)}
    />
    <TextField
      className="mb-4"
      fullWidth
      id="user-graduation-semester"
      inputProps={{
        min: MIN_SEMESTER,
        max: MAX_SEMESTER,
        step: 1,
      }}
      label={`Graduation Semester ( ${MIN_SEMESTER} - ${MAX_SEMESTER} )`}
      onBlur={handleSemesterBlur}
      onChange={handleSemesterChange}
      type="number"
      value={graduationSemester}
    />
    <TextField
      className="mb-4"
      fullWidth
      id="user-graduation-year"
      inputProps={{
        min: MIN_GRADUATION_YEAR,
        max: MAX_GRADUATION_YEAR,
        step: 1,
      }}
      label={`Graduation Year ( ${MIN_GRADUATION_YEAR} - ${MAX_GRADUATION_YEAR} )`}
      onBlur={handleYearBlur}
      onChange={handleYearChange}
      type="number"
      value={graduationYear}
    />
    <FormControlLabel
      control={
        <Checkbox 
          checked={exclude}
          onChange={() => setExclude(p => !p)}
          name="Exclude"
          color="primary"
      />}
      label="Exclude"
    />
    <TextField
      id="user-notes"
      label="Notes"
      type="string"
      className="mb-4"
      value={notes}
      fullWidth
      multiline
      onChange={(e) => setNotes(e.currentTarget.value)}
    />
    <Button color="primary" onClick={triggerUpdateUser}>
      Update User
    </Button>
    <Button color="primary" onClick={triggerPasswordReset}>
      Trigger Password Reset
    </Button>
    {infoMessage && (
      <Alert severity="success">{infoMessage}</Alert>
    )}
    </div>
  );
}

export default UserTab;
