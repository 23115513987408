import api from '.';

const URL = process.env.REACT_APP_API_URL;
const USERS_URL = `${URL}/mbadmin/user`;
const PUBLIC_USERS_URL = `${URL}/public/users`;

const createUser = async (dispatch, data) => {
  return api.adminApi(dispatch, 'post', PUBLIC_USERS_URL, data);
};

const getUsers = async (dispatch, params) => {
  return api.adminApi(dispatch, 'get', `${USERS_URL}`, null, params);
};

const getUser = async (dispatch, id) => {
  return api.adminApi(dispatch, 'get', `${USERS_URL}/${id}`);
};

const updateUser = async (dispatch, user, id) => {
  return api.adminApi(dispatch, 'post', `${USERS_URL}/${id}`, user);
};

const deleteUser = async (dispatch, id) => {
  return api.adminApi(dispatch, 'delete', `${USERS_URL}/${id}`);
};

const syncUsers = async (dispatch) => {
  return api.adminApi(dispatch, 'get', `${USERS_URL}/sync`);
};

const triggerResetPassword = async (dispatch, email) => {
  return api.adminApi(
    dispatch,
    'post',
    `${URL}/user-forgot-password`,
    {email}
  );
};

const getPointsSummary = async (dispatch, userId) => {
  return api.adminApi(
    dispatch,
    'get',
    `${USERS_URL}/${userId}/points`
  )
};

const getGroupPointsBreakdown = async (dispatch, userId, groupId) => {
  return api.adminApi(
    dispatch,
    'get',
    `${USERS_URL}/${userId}/group/${groupId}/points`
  )
};

const addBonusPoints = async (dispatch, userId, groupId, points) => {
  return api.adminApi(
    dispatch,
    'post',
    `${USERS_URL}/${userId}/group/${groupId}/points`,
    {points}
  )
};

export default {
  createUser,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  syncUsers,
  triggerResetPassword,
  getPointsSummary,
  getGroupPointsBreakdown,
  addBonusPoints
};
