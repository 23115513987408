import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_NOTIFICATIONS,
  GET_GROUP_POINTS_SUMMARY,
  GET_POINTS_SUMMARY,
} from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.updated,
      };
    case DELETE_USER:
      return {
        ...state,
        user: null,
      };
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_POINTS_SUMMARY:
      return {
        ...state,
        pointsSummary: action.payload
      };
    case GET_GROUP_POINTS_SUMMARY:
      return {
        ...state,
        groupPointsSummary: action.payload
      };
    default:
      return state;
  }
};
