import {
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table, 
  Button,
  CircularProgress
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import { bulkImportEventsAction } from '../../store/actions/event.actions';

const CLEAR_MESSAGE_TIME = 3000;

const BulkEventUpload = ({ groupId }) => {
  const [events, setEvents] = useState([])
  const [alertData, setAlertData] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (ref.current) {
      ref.current.open(e)
    }
  }

  const handleFileSelected = (data) => {
    const newEvents = [];
    for (const row of data) {
      newEvents.push({
        groupId,
        name: row.data.name || '',
        description: row.data.description || '',
        owner: row.data.owner || '',
        endUser: row.data.endUser?.split(',') || [],
        website: row.data.website || '',
        fieldOfFocus: row.data.fieldOfFocus?.split(',') || [],
        additionalInfo: row.data.additionalInfo || '',
        category: row.data.category || '',
        subCategory: row.data.subCategory?.split(',') || [],
        contact: {
          prefix: row.data.contactPrefix || '',
          firstName: row.data.contactFirstName || '',
          lastName: row.data.contactLastName || '',
          phoneNumber: row.data.contactPhoneNumber || '',
          email:row.data.contactEmail ||  ''
        },
        headerPhotoUrl: row.data.headerPhotoUrl ||  '',
        attendeePoints: row.data.attendeePoints ||  100,
        startTime: row.data.startTime ||  new Date(),
        endTime: row.data.endTime ||  new Date(),
        lastCheckInTime: row.data.lastCheckInTime ||  new Date(),
        location: row.data.locationLat && row.data.locationLon ? {
          latitude: row.data.locationLat,
          longitude: row.data.locationLon
        } : null,
        locationRadius: row.data.locationRadius || 30,
        minimumThresholdTimeSpan: row.data.minimumThresholdTimeSpan || 50,
        approvalStatus: "APPROVED",
        publishFrom: row.data.publishFrom || new Date(),
        publishTo: row.data.publishTo || new Date(),
        type: row.data.type?.split(',') || [],
        department: row.data.department || '',
        accessInfo: row.data.accessInfo || '',
        rsvpLink: row.data.rsvpLink || '',
        meetingLink: row.data.meetingLink || '',
        campus: row.data.campus || false,
        locationDescription: row.data.locationDescription || '',
        address1: row.data.address1 || '',
        address2: row.data.address2 || '',
        city: row.data.city || '',
        state: row.data.state || '',
        country: row.data.country || '',
        zip: row.data.zip || '',
        building: row.data.building || '',
        locationMapUrl: row.data.locationMapUrl || '',
        theme: row.data.theme?.split(',') || [],
        groupNotes: row.data.groupNotes || '',
        adminNotes: row.data.adminNotes || '',
        archive: row.data.archive || false,
      });
    }
    setEvents(newEvents);
    setAlertData(null);
  }

  const handleError = () => {
    setAlertData({
      severity: 'error',
      message: 'Unable to upload CSV, please double check the file'
    });
    setTimeout(() => setAlertData(null), CLEAR_MESSAGE_TIME);
  }

  const handleRemoveFile = (e) => {
    if (ref.current) {
      ref.current.removeFile(e)
    }
    setEvents([]);
  }

  const importData = async () => {
    setIsImporting(true);
    await dispatch(bulkImportEventsAction(groupId, events));
    handleRemoveFile();
    setIsImporting(false);
    setIsSuccessful(true);
    setTimeout(() => setIsSuccessful(false), CLEAR_MESSAGE_TIME);
  };

  return (
    <div className="bulk-event-upload">
      {!!alertData && (
        <Alert className="d-flex align-items-center" severity={alertData.severity}>{alertData.message}</Alert>
      )}
      {isSuccessful && (
        <Alert className="d-flex align-items-center" severity="success">Events imported successfully</Alert>
      )}
      <div className="d-flex">
        <CSVReader
            ref={ref}
            onFileLoad={handleFileSelected}
            onError={handleError}
            noDrag
            config={{ header: true, dynamicTyping: true, skipEmptyLines: true }}
            >
            {({ file }) => (
              <div className="d-flex align-items-center">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={handleOpenDialog}
                >
                    Browse file
                </Button>
                <div>
                  {file && file.name}
                </div>
                {!!file && <Button color="secondary" onClick={handleRemoveFile}><Delete /></Button>}
              </div>
            )}
          </CSVReader>
        </div>
          {!!events.length && (
            <div className="import-preview">
              <h3>Events to be added</h3>
              <TableContainer component={Paper}>
              <Table size="small" padding="none">
                <TableHead className="bg-dark">
                  <TableRow>
                  <TableCell className="text-center text-white">Name</TableCell>
                  <TableCell className="text-center text-white">Description</TableCell>
                  <TableCell className="text-center text-white">Owner</TableCell>
                  <TableCell className="text-center text-white">End User</TableCell>
                  <TableCell className="text-center text-white">Website</TableCell>
                  <TableCell className="text-center text-white">Field Of Focus</TableCell>
                  <TableCell className="text-center text-white">Additional Info</TableCell>
                  <TableCell className="text-center text-white">Category</TableCell>
                  <TableCell className="text-center text-white">Sub-Category </TableCell>
                  <TableCell className="text-center text-white">Contact Prefix</TableCell>
                  <TableCell className="text-center text-white">Contact First Name</TableCell>
                  <TableCell className="text-center text-white">Contact Last Name</TableCell>
                  <TableCell className="text-center text-white">Contact Phone Number</TableCell>
                  <TableCell className="text-center text-white">Contact Email</TableCell>
                  <TableCell className="text-center text-white">Header Photo Url</TableCell>
                  <TableCell className="text-center text-white">Attendee Points</TableCell>
                  <TableCell className="text-center text-white">Start Time</TableCell>
                  <TableCell className="text-center text-white">End Time</TableCell>
                  <TableCell className="text-center text-white">Last Check-In Time</TableCell>
                  <TableCell className="text-center text-white">Latitude</TableCell>
                  <TableCell className="text-center text-white">Longitude</TableCell>
                  <TableCell className="text-center text-white">Location Radius</TableCell>
                  <TableCell className="text-center text-white">Minimum Threshold TimeSpan</TableCell>
                  <TableCell className="text-center text-white">Approval Status</TableCell>
                  <TableCell className="text-center text-white">Publish From</TableCell>
                  <TableCell className="text-center text-white">Publish To</TableCell>
                  <TableCell className="text-center text-white">Type</TableCell>
                  <TableCell className="text-center text-white">Department</TableCell>
                  <TableCell className="text-center text-white">Access Info</TableCell>
                  <TableCell className="text-center text-white">RSVP Link</TableCell>
                  <TableCell className="text-center text-white">Meeting Link</TableCell>
                  <TableCell className="text-center text-white">Campus</TableCell>
                  <TableCell className="text-center text-white">Location Description</TableCell>
                  <TableCell className="text-center text-white">Address1</TableCell>
                  <TableCell className="text-center text-white">Address2</TableCell>
                  <TableCell className="text-center text-white">City</TableCell>
                  <TableCell className="text-center text-white">State</TableCell>
                  <TableCell className="text-center text-white">Country</TableCell>
                  <TableCell className="text-center text-white">Zip</TableCell>
                  <TableCell className="text-center text-white">Building</TableCell>
                  <TableCell className="text-center text-white">Location Map Url</TableCell>
                  <TableCell className="text-center text-white">Theme</TableCell>
                  <TableCell className="text-center text-white">Group Notes</TableCell>
                  <TableCell className="text-center text-white">Admin Notes</TableCell>
                  <TableCell className="text-center text-white">Archive</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event, i) => (
                    <TableRow key={i}>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>{event.description}</TableCell>
                      <TableCell>{event.owner}</TableCell>
                      <TableCell>{event.endUser.join(', ')}</TableCell>
                      <TableCell>{event.website}</TableCell>
                      <TableCell>{event.fieldOfFocus.join(', ')}</TableCell>
                      <TableCell>{event.additionalInfo}</TableCell>
                      <TableCell>{event.category}</TableCell>
                      <TableCell>{event.subCategory.join(', ') }</TableCell>
                      <TableCell>{event.contact?.prefix}</TableCell>
                      <TableCell>{event.contact?.firstName}</TableCell>
                      <TableCell>{event.contact?.lastName}</TableCell>
                      <TableCell>{event.contact?.phoneNumber}</TableCell>
                      <TableCell>{event.contact?.email}</TableCell>
                      <TableCell>{event.headerPhotoUrl}</TableCell>
                      <TableCell>{event.attendeePoints}</TableCell>
                      <TableCell>{format(new Date(event.startTime), 'MM/dd/yyyy')}</TableCell>
                      <TableCell>{format(new Date(event.endTime), 'MM/dd/yyyy')}</TableCell>
                      <TableCell>{format(new Date(event.lastCheckInTime), 'MM/dd/yyyy')}</TableCell>
                      <TableCell>{event.location?.latitude}</TableCell>
                      <TableCell>{event.location?.longitude}</TableCell>
                      <TableCell>{event.locationRadius}</TableCell>
                      <TableCell>{event.minimumThresholdTimeSpan}</TableCell>
                      <TableCell>{event.approvalStatus}</TableCell>
                      <TableCell>{format(new Date(event.publishFrom), 'MM/dd/yyyy')}</TableCell>
                      <TableCell>{format(new Date(event.publishTo), 'MM/dd/yyyy')}</TableCell>
                      <TableCell>{event.type.join(', ')}</TableCell>
                      <TableCell>{event.department}</TableCell>
                      <TableCell>{event.accessInfo}</TableCell>
                      <TableCell>{event.rsvpLink}</TableCell>
                      <TableCell>{event.meetingLink}</TableCell>
                      <TableCell>{event.campus.toString()}</TableCell>
                      <TableCell>{event.locationDescription}</TableCell>
                      <TableCell>{event.address1}</TableCell>
                      <TableCell>{event.address2}</TableCell>
                      <TableCell>{event.city}</TableCell>
                      <TableCell>{event.state}</TableCell>
                      <TableCell>{event.country}</TableCell>
                      <TableCell>{event.zip}</TableCell>
                      <TableCell>{event.building}</TableCell>
                      <TableCell>{event.locationMapUrl}</TableCell>
                      <TableCell>{event.theme.join(', ')}</TableCell>
                      <TableCell>{event.groupNotes}</TableCell>
                      <TableCell>{event.adminNotes}</TableCell>
                      <TableCell>{event.archive.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              <div className="import-actions mt-4">
                <Button color="primary" variant="contained" onClick={importData}>
                  {isImporting ? <CircularProgress /> : 'Import'}
                </Button>
            </div>
            </div>
          )}
    </div>
  )
}

export default BulkEventUpload;
