import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Delete, Add, Cancel } from '@material-ui/icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableBody,
  TablePagination,
  Paper,
  Button,
  Table,
  IconButton,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import {
  getGroupsAction,
  deleteGroupAction,
} from '../store/actions/group.actions';
import ConfirmationModal from '../components/ConfirmationModal';
import FilterModal from '../components/Filter';
import { PAGINATION_OPTIONS } from '../constants';

import './Groups.scss';

const GROUP_FIELDS = [
  {
    display: 'Name',
    render: (g) => g.name,
    sortField: 'name',
  },
  {
    display: 'Description',
    render: (g) => g.description,
    sortField: 'description',
  }
];

const Groups = () => {
  const groups = useSelector((state) => state.group.groups);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [groupIdToDelete, setGroupIdToDelete] = useState('');
  const [filterName, setFilterName] = useState('');
  const [currentFilter, setCurrentFilter] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const loadGroups = async () => {
      setIsLoading(true);
      await dispatch(getGroupsAction());
      setIsLoading(false);
    };
    loadGroups();
  }, [dispatch]);

  const getGroups = (newCountPerPage, newPageNumber) => {
    let ordering;
    if (sortField) {
      ordering = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(
      getGroupsAction({
        filter: currentFilter,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
        ordering,
      })
    );
  };
  const goToGroup = (id) => {
    history.push(`/groups/${id}`);
  };

  const deleteGroup = async () => {
    setIsLoading(true);
    setShowConfirmationModal(false);
    await dispatch(deleteGroupAction(groupIdToDelete));
    await dispatch(getGroupsAction());
    setIsLoading(false);
  };
  const openConfirmDelete = (e, id) => {
    e.stopPropagation();
    setGroupIdToDelete(id);
    setShowConfirmationModal(true);
  };
  const saveFilters = async () => {
    const filter = {};
    if (filterName) {
      filter.name = `${filterName}`;
    }
    setCurrentFilter(filter);
    let sort;
    if (sortField) {
      sort = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(getGroupsAction({ filter, sort }));
  };
  const resetFilters = () => {
    setFilterName('');
    setCurrentFilter({});
    dispatch(getGroupsAction());
  };
  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getGroups(countPerPage, newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    const newPageNumber = 0;
    setPageNumber(newPageNumber);
    getGroups(newCountPerPage, newPageNumber);
  };
  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
    let ordering;
    if (field) {
      ordering = `${
        newSortDirection === 'desc' ? encodeURIComponent('-') : ''
      }${field}`;
    }
    dispatch(
      getGroupsAction({
        filter: currentFilter,
        ordering,
      })
    );
  };
  return (
    <div className="groups">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Groups</span>
        <Button
          variant="outlined"
          color="primary"
          className="mx-2 px-1"
          onClick={() => goToGroup('create')}
        >
          <Add />
          Add
        </Button>
        <div className="flex-grow-1" />
        {(filterName) && (
          <IconButton onClick={resetFilters}>
            <Cancel color="error" />
          </IconButton>
        )}
        <FilterModal onSave={saveFilters} itemName="Groups">
          <div>
            <TextField
              id="filter-name"
              label="Name"
              placeholder="Enter a name"
              value={filterName}
              onChange={(e) => setFilterName(e.currentTarget.value)}
            />
          </div>
        </FilterModal>
      </h2>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!groups && (
        <>
          <TableContainer component={Paper}>
            <Table size="small" padding="none" className="table">
              <TableHead className="bg-dark">
                <TableRow>
                  {GROUP_FIELDS.map((field) => {
                    return (
                      <TableCell key={field.display} align="left">
                        <TableSortLabel
                          active={field.sortField === sortField}
                          className="text-white"
                          direction={
                            field.sortField === sortField
                              ? sortDirection
                              : 'desc'
                          }
                          hideSortIcon={!field.sortField}
                          onClick={() => updateSort(field.sortField)}
                        >
                          {field.display}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.data.map((group) => (
                  <TableRow
                    key={group.id}
                    hover
                    className="cursor-pointer"
                    onClick={() => goToGroup(group.id)}
                  >
                    {GROUP_FIELDS.map((field) => (
                      <TableCell style={{paddingLeft: "5px"}} key={field.display} align="left">
                        <div className="ellipsis">{field.render(group)}</div>
                        
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <Link to={`groups/${group.id}/invitecodes/`} onClick={(e) => {
                          e.stopPropagation();
                        
                      }}>Invite Codes</Link>
                      <IconButton
                        onClick={(e) => openConfirmDelete(e, group.id)}
                        disabled={true}
                      >
                        {/* If disabled make color gray */}
                        <Delete style={{color: 'gray'}} color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={countPerPage}
            component="div"
            count={groups.totalCount}
            page={pageNumber}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={deleteGroup}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete Group"
        bodyText="Are you sure you want to delete this group?"
      />
    </div>
  );
};

export default Groups;
