import {
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table, 
  Button,
  CircularProgress
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import EventSearch from '../../components/EventSearch';
import { bulkAttendEventsAction } from '../../store/actions/group.actions';

const CLEAR_MESSAGE_TIME = 3000;

const BulkPointsTab = (props) => {
  const {group} = props;
  const [attendees, setAttendees] = useState([]);
  const [alertData, setAlertData] = useState(null);
  const [isSearchingEvents, setIsSearchingEvents] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [event, setEvent] = useState(null);
  const [emailsAttended, setEmailsAttended] = useState([]);

  const dispatch = useDispatch();
  const ref = useRef();
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (ref.current) {
      ref.current.open(e)
    }
  }
  const handleFileSelected = (data) => {
    const newAttendees = [];
    for (const row of data) {
      const email = row.data.email || row.data.Email;
      const checkinTime = row.data.checkin || row.data.checkIn || row.data.CheckIn;
      if (email && checkinTime) {
        newAttendees.push({email, checkinTime});
      }
    }
    setAttendees(newAttendees);
    setAlertData(null);
  }
  const handleError = () => {
    setAlertData({
      severity: 'error',
      message: 'Unable to upload CSV, please double check the file'
    });
    setTimeout(() => setAlertData(null), CLEAR_MESSAGE_TIME);
  }
  const handleRemoveFile = (e) => {
    if (ref.current) {
      ref.current.removeFile(e)
    }
    setAttendees([]);
  }
  const importData = async () => {
    setIsImporting(true);
    const response = await dispatch(bulkAttendEventsAction(group.id, {attendees, groupEventId: event.groupEventId}))
    setEmailsAttended(response.data);
    handleRemoveFile();
    setEvent(null);
    setAlertData({
      severity: 'success',
      message: (
        <div>
          <h4>Users attended</h4>
          <ul>{response.data.map(email => <li key={email}>{email}</li>)}</ul>
        </div>
      )
    });
    setIsImporting(false);
  };
  const selectEvent = (e) => {
    setEvent(e);
    setIsSearchingEvents(false);
    setAlertData(null);
  };
  return (
    <div className="bulk-points-tab">
      {!!alertData && (
        <Alert className="d-flex align-items-center" severity={alertData.severity}>{alertData.message}</Alert>
      )}
      <div className="d-flex">
        <Button className="mr-4" color="primary" variant="contained" onClick={() => setIsSearchingEvents(true)}>Select Event</Button>
        <EventSearch show={isSearchingEvents} onHide={() => setIsSearchingEvents(false)} selectEvent={selectEvent} />
        <CSVReader
          ref={ref}
          onFileLoad={handleFileSelected}
          onError={handleError}
          noDrag
          config={{header: true}}
          >
          {({ file }) => (
            <div className="d-flex align-items-center">
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                onClick={handleOpenDialog}
              >
                  Browse file
              </Button>
              <div>
                {file && file.name}
              </div>
              {!!file && <Button color="secondary" onClick={handleRemoveFile}><Delete /></Button>}
            </div>
          )}
        </CSVReader>
      </div>
      {!!event && (
        <h2>Uploading attendance for {event.name}</h2>
      )}
      {!!attendees.length && (
        <div className="import-preview" style={{maxWidth: '25vw'}}>
          <h3>Import Preview</h3>
          <TableContainer component={Paper}>
              <Table size="small" padding="none">
                <TableHead className="bg-dark">
                  <TableRow>
                    <TableCell className="text-center text-white">
                      Email
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Check In Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendees.map(({email, checkinTime}) => (
                    <TableRow key={email}>
                      <TableCell>{email}</TableCell>
                      <TableCell>{format(new Date(checkinTime), 'MM/dd/yyyy')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!!event && (<div className="import-actions mt-4">
              <Button color="primary" variant="contained" onClick={importData}>
                {isImporting ? <CircularProgress /> : 'Import'}
              </Button>
            </div>)}
        </div>
      )}
    </div>
  )
}

export default BulkPointsTab;
