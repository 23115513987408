import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
}));

const generatePropsFromIndex = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`tabpanel-${index}`}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const CustomTabs = ({
    tabs,
    tabItems
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab, index) => <Tab key={tab.label} label={tab.label} {...generatePropsFromIndex(index)} />)}
        </Tabs>
      </AppBar>
      {tabItems.map((tabItem, index) => <TabPanel key={index} value={value} index={index}>{tabItem}</TabPanel>)}
    </div>
  );
}

export default CustomTabs;
