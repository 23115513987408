import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Delete, Add, Cancel, FileCopy } from '@material-ui/icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableBody,
  TablePagination,
  Paper,
  Button,
  Table,
  IconButton,
  TextField,
  LinearProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import _ from 'lodash';
import { format, isBefore, isAfter, parseISO, formatISO } from 'date-fns'

import {
  getEventsAction,
  deleteEventAction,
  cloneEventAction,
} from '../store/actions/event.actions';
import ConfirmationModal from '../components/ConfirmationModal';
import FilterModal from '../components/Filter';
import { PAGINATION_OPTIONS } from '../constants';

import './Events.scss';
import { CLONE_EVENT } from '../store/types';

const EVENT_FIELDS = [
  {
    display: 'Name',
    render: (g) => g.name,
    sortField: 'name',
  },
  {
    display: 'Description',
    renderAsHtml: true,
    render: (g) => g.description,
    sortField: 'description',
  }, 
  {
    display: 'Group',
    render: (g) => g.groupName,
    sortField: 'groupName',
  },   {
    display: 'Category',
    render: (g) => g.category,
    sortField: 'category',
  }, {
    display: 'Start Date',
    render: (g) => format(new Date(g.startTime), 'MM/dd/yyyy hh:mm aa'),
    sortField: 'startTime',
  }, 
  {
    display: 'Admin Notes',
    render: (g) => g.adminNotes,
    sortField: 'adminNotes',
  }, 
];

const Events = () => {
  const authUser = useSelector((state) => state.auth.userInfo);
  const events = useSelector((state) => state.event.events);
  const clonedEvent = useSelector((state) => state.event.clone);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState('');
  const [eventToClone, setEventToClone] = useState(null);
  const [currentFilter, setCurrentFilter] = useState({});
  const [newFilter, setNewFilter] = useState({
    name: '',
    isArchived: false,
    dateRange: {
      startTime: '',
      endTime: ''
    }
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const loadEvents = async () => {
      setIsLoading(true);
      await dispatch(getEventsAction());
      setIsLoading(false);
    };
    if (!clonedEvent) {
      loadEvents();
    } else {
      history.push(`/events/${clonedEvent}`);
      dispatch({type: CLONE_EVENT, payload: null});
    }
  }, [dispatch, clonedEvent]);

  const getEvents = (newCountPerPage, newPageNumber) => {
    let ordering;
    if (sortField) {
      ordering = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(
      getEventsAction({
        filter: currentFilter,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
        ordering,
      })
    );
  };
  const goToEvent = (id) => {
    history.push(`/events/${id}`);
  };

  const deleteEvent = async () => {
    setIsLoading(true);
    setShowConfirmationModal(false);
    await dispatch(deleteEventAction(eventIdToDelete));
    await dispatch(getEventsAction());
    setIsLoading(false);
  };
  const cloneEvent = async () => {
    setIsLoading(true);
    setShowConfirmationModal(false);
    await dispatch(cloneEventAction(eventToClone.id, eventToClone.groupId));
    await dispatch(getEventsAction());
    setIsLoading(false);
  };
  const openConfirmDelete = (e, id) => {
    e.stopPropagation();
    setEventIdToDelete(id);
    setShowConfirmationModal(true);
  };
  const openConfirmClone = (e, event) => {
    e.stopPropagation();
    setEventToClone(event);
    setShowConfirmationModal(true);
  };
  const setFilter = (key, value) => {
    const newFilterData = _.cloneDeep(newFilter);

    switch (key) {
      case 'dateRange_startTime':
      case 'dateRange_endTime':
        if (!value || value.toString() === 'Invalid Date') {
          return;
        }
        const dateRangeKey = key.replace('dateRange_', '');

        newFilterData.dateRange[dateRangeKey] = formatISO(value);
        break;
      default:
        newFilterData[key] = value;
        break;
    }

    setNewFilter(newFilterData);
  }
  const saveFilters = async () => {
    const filter = {
      isArchived: newFilter.isArchived
    };
    if (newFilter.name) {
      filter.name = `${newFilter.name}`;
    }
    if (newFilter.dateRange.startTime || newFilter.dateRange.endTime) {
      filter.dateRange = newFilter.dateRange;
    }
    setCurrentFilter(filter);
    let sort;
    if (sortField) {
      sort = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    dispatch(getEventsAction({ filter, sort }));
  };
  const resetFilters = () => {
    setCurrentFilter({});
    setNewFilter({
      name: '',
      isArchived: false,
      dateRange: {
        startTime: '',
        endTime: ''
      }
    });
    dispatch(getEventsAction());
  };
  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getEvents(countPerPage, newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    const newPageNumber = 0;
    setPageNumber(0);
    getEvents(newCountPerPage, newPageNumber);
  };
  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
    let ordering;
    if (field) {
      ordering = `${
        newSortDirection === 'desc' ? encodeURIComponent('-') : ''
      }${field}`;
    }
    dispatch(
      getEventsAction({
        filter: currentFilter,
        ordering,
      })
    );
  };

  const {
    dateRange: {
      startTime: drStartTime,
      endTime: drEndTime
    }
  } = newFilter;
  const parsedDRStartTime = drStartTime ? parseISO(drStartTime) : null;
  const parsedDREndTime = drEndTime ? parseISO(drEndTime) : null;

  return (
    <div className="events">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Events</span>
        <Button
          variant="outlined"
          color="primary"
          className="mx-2 px-1"
          onClick={() => goToEvent('create')}
        >
          <Add />
          Add
        </Button>
        <div className="flex-grow-1" />
        {(newFilter.name || newFilter.isArchived || parsedDRStartTime || parsedDREndTime) && (
          <IconButton onClick={resetFilters}>
            <Cancel color="error" />
          </IconButton>
        )}
        <FilterModal onSave={saveFilters} itemName="Events" disabled={!!parsedDRStartTime && !!parsedDREndTime && (isAfter(parsedDRStartTime, parsedDREndTime) || isBefore(parsedDREndTime, parsedDRStartTime))}>
          <div>
            <TextField
              id="filter-name"
              label="Name"
              placeholder="Enter a name"
              value={newFilter.name}
              onChange={(e) => setFilter('name', e.currentTarget.value)}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={newFilter.isArchived}
                  onChange={(e) => setFilter('isArchived', e)}
                  name="Is Archived"
                  color="primary"
              />}
              label="Show Archived"
            />
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={true}
                label="Start time: Any event at or after date"
                value={parsedDRStartTime ?? new Date()}
                error={!!parsedDRStartTime && !!parsedDREndTime && isAfter(parsedDRStartTime, parsedDREndTime)}
                helperText={!!parsedDRStartTime && !!parsedDREndTime && isAfter(parsedDRStartTime, parsedDREndTime) && "Start time can't be after the end time"}
                onChange={(date) => setFilter('dateRange_startTime', date)}
                onError={console.log}
                format="MM/dd/yyyy hh:mm a"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={true}
                label="End time: Any event at or before date"
                value={parsedDREndTime ?? new Date()}
                error={!!parsedDRStartTime && !!parsedDREndTime && isBefore(parsedDREndTime, parsedDRStartTime)}
                helperText={!!parsedDRStartTime && !!parsedDREndTime && isBefore(parsedDREndTime, parsedDRStartTime) && "End time can't be before the start time"}
                onChange={(date) => setFilter('dateRange_endTime', date)}
                onError={console.log}
                format="MM/dd/yyyy hh:mm a"
              />
            </MuiPickersUtilsProvider>
          </div>
        </FilterModal>
      </h2>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!events && (
        <>
          <TableContainer component={Paper}>
            <Table size="small" padding="none">
              <TableHead className="bg-dark">
                <TableRow>
                  {EVENT_FIELDS.map((field) => {
                    return (
                      <TableCell key={field.display} align="center">
                        <TableSortLabel
                          active={field.sortField === sortField}
                          className="text-white"
                          direction={
                            field.sortField === sortField
                              ? sortDirection
                              : 'desc'
                          }
                          hideSortIcon={!field.sortField}
                          onClick={() => updateSort(field.sortField)}
                        >
                          {field.display}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.data.map((event) => (
                  <TableRow
                    key={event.id}
                    hover
                    className="cursor-pointer"
                    onClick={() => goToEvent(event.id)}
                  >
                    {EVENT_FIELDS.map((field) => 
                      !field.renderAsHtml ? (
                      <TableCell style={{paddingLeft: "5px"}} key={field.display} align="left">
                        {field.render(event)}
                      </TableCell>
                    ) : (
                      <TableCell key={field.display} align="left">
                        <span dangerouslySetInnerHTML={{ __html: field.render(event)}} />
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <IconButton
                        onClick={(e) => openConfirmClone(e, event)}
                      >
                        <FileCopy color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={(e) => openConfirmDelete(e, event.groupEventId)}
                        disabled={!authUser || !authUser.isAdmin}
                      >
                        {/* If disabled make color gray */}
                        <Delete style={ (!authUser || !authUser.isAdmin) ? {color: 'gray'} : {}} color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={countPerPage}
            component="div"
            count={events.totalCount}
            page={pageNumber}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
      <ConfirmationModal
        show={eventIdToDelete && showConfirmationModal}
        onConfirm={deleteEvent}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete Event"
        bodyText="Are you sure you want to delete this event?"
      />
      {eventToClone && (
        <ConfirmationModal
          show={showConfirmationModal}
          onConfirm={cloneEvent}
          onCancel={() => setShowConfirmationModal(false)}
          headerText="Clone Event"
          bodyText={`Are you sure you want to clone ${eventToClone.name}?`}
        />
      )}
    </div>
  );
};

export default Events;
