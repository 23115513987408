import eventApi from '../../api/event.api';

import {
  GET_EVENTS,
  GET_EVENT,
  UPDATE_EVENT,
  CREATE_EVENT,
  DELETE_EVENT,
  CLONE_EVENT
} from '../types';

export const createEventAction = (data) => async (dispatch) => {
  const event = await eventApi.createEvent(dispatch, data);
  dispatch({
    type: CREATE_EVENT,
    payload: event,
  });
  return event;
};

export const getEventsAction = (params) => async (dispatch) => {
  const events = await eventApi.getEvents(dispatch, params);
  dispatch({
    type: GET_EVENTS,
    payload: events,
  });
};

export const getEventAction = (id) => async (dispatch) => {
  const event = await eventApi.getEvent(dispatch, id);
  dispatch({
    type: GET_EVENT,
    payload: event,
  });
};

export const updateEventAction = (event, id) => async (dispatch) => {
  const updatedEvent = await eventApi.updateEvent(dispatch, event, id);
  dispatch({
    type: UPDATE_EVENT,
    payload: updatedEvent,
  });
  return updatedEvent;
};

export const deleteEventAction = (id) => async (dispatch) => {
  await eventApi.deleteEvent(dispatch, id);
  dispatch({
    type: DELETE_EVENT,
  });
};

export const cloneEventAction = (id, groupId) => async (dispatch) => {
  const event = await eventApi.cloneEvent(dispatch, id, groupId);
  dispatch({
    type: CLONE_EVENT,
    payload: event.id
  });
  dispatch({
    type: GET_EVENT,
    payload: event,
  });
}

export const bulkImportEventsAction = (groupId, events) => async (dispatch) => {
  await eventApi.bulkImportEvents(dispatch, groupId, events);
}
