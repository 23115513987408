import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Grid, LinearProgress } from '@material-ui/core';
import { getUserAction } from '../store/actions/user.actions';
import CustomTabs from '../components/Tabs';
import UserTab from './User/UserTab';
import PointsTab from './User/PointsTab';

import './User.scss';

const User = () => {
  const params = useParams();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);
      await dispatch(getUserAction(params.id));
      setIsLoading(false);
    };
    if (!user || user.oktaUserId !== params.id) {
      loadUser();
    }
  }, [dispatch, params.id, user]);
  if (!params.id) {
    return <Redirect to="/users" />;
  }
  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }
  return (
    <Grid container className="user">
      <Grid item sm={3} />
      <Grid item sm={6}>
        <CustomTabs
          tabs={[{label:'User'},{label: 'Points'}]}
          tabItems={[
            <UserTab user={user} groupId={params.groupId} />,
            <PointsTab user={user} groupId={params.groupId} />
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default User;
