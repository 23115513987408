export const DEFAULT_TIMEOUT = 5000;
export const PAGINATION_OPTIONS = [10, 25, 50];
export const MS_PER_MINUTE = 60000;

export const LOCAL_STORAGE_KEYS = {
  IS_SUPER: 'mb.is_super'
}

export const ZIP_LIMIT = 10;
export const THEME_LIMIT = 128;
export const TINY_TEXT_LIMIT = 100;
export const SMALL_TEXT_LIMIT = 255;
export const STANDARD_TEXT_LIMIT = 512;
export const LARGE_TEXT_LIMIT = 1024;
export const EXTRA_LARGE_TEXT_LIMIT = 2048;
export const MAX_TEXT_LIMIT = 4000;