import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Dialog, DialogTitle, TextField, DialogContent, DialogActions } from '@material-ui/core';
import { addBonusPointsAction, getGroupPointsBreakdownAction, getPointsSummaryAction } from '../../store/actions/user.actions';

const POINT_SUMMARY_FIELDS = [
  {display: 'Name', render: ps => ps.name},
  {display: 'Points Earned', render: ps => ps.points}
];

const PointsTab = (props) => {
  const {user, groupId} = props;
  const dispatch = useDispatch();
  const pointsSummary = useSelector((state) => state.user.pointsSummary);
  const groupPointsSummary = useSelector((state) => state.user.groupPointsSummary);
  const [isLoading, setIsLoading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [showAddPoint, setShowAddPoints] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);

  useEffect(() => {
    const loadPointsSummary = async () => {
      setIsLoading(true);
      await dispatch(getPointsSummaryAction(user.id));
      setIsLoading(false);
    }
    if (!pointsSummary) {
      loadPointsSummary();
    }
  }, [pointsSummary])

  const goToGroupBreakdown = async (groupId, name) => {
    setIsLoading(true);
    await dispatch(getGroupPointsBreakdownAction(user.id, groupId));
    setGroupName(name);
    setIsLoading(false);
  }

  const savePoints = async () => {
    setIsLoading(true);
    await dispatch(addBonusPointsAction(user.id, groupId, bonusPoints));
    setIsLoading(false);
    setShowAddPoints(false);
  }

  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }

  if (!pointsSummary) {
    return <div>An error occurred</div>
  }

  const data = groupName ? groupPointsSummary : pointsSummary;

  return (
    <div className="points-tab">
      <h2>{groupName ? `Points breakdown for ${groupName}` : 'Points summary'}</h2>
        {groupName && (<Button onClick={() => setGroupName('')}>&lt; GO BACK</Button>)}
        <TableContainer component={Paper}>
          <Table size="small" padding="none" className="w-100">
            <TableHead className="bg-dark">
              <TableRow>
                {POINT_SUMMARY_FIELDS.map((field) => (
                  <TableCell key={field.display}>
                    <TableSortLabel className="text-white">{field.display}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.filter(d => groupName || !groupId || d.groupId === groupId).map((ps, i) => (
                <TableRow key={i} hover className="cursor-pointer" onClick={() => goToGroupBreakdown(ps.groupId, ps.name)}>
                  {POINT_SUMMARY_FIELDS.map((field) => (
                    <TableCell key={field.display}>
                      {field.render(ps)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog onClose={() => setShowAddPoints(false)} open={showAddPoint}>
          <DialogTitle>Add bonus points</DialogTitle>
          <DialogContent>
            <TextField className="mb-4" type="number" value={bonusPoints} onChange={(e) => setBonusPoints(e.currentTarget.value)} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={savePoints}>
              Save
            </Button>
            <Button color="primary" variant="outlined" onClick={() => setShowAddPoints(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}

export default PointsTab;
