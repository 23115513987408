import userApi from '../../api/user.api';

import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  PASSWORD_RESET,
  GET_USER_NOTIFICATIONS,
  SYNC_USERS,
  GET_POINTS_SUMMARY,
  GET_GROUP_POINTS_SUMMARY,
} from '../types';

export const createUserAction = (data) => async (dispatch) => {
  const user = await userApi.createUser(dispatch, data);
  dispatch({
    type: CREATE_USER,
    payload: user,
  });
};

export const getUsersAction = (params) => async (dispatch) => {
  const users = await userApi.getUsers(dispatch, params);
  dispatch({
    type: GET_USERS,
    payload: users,
  });
};

export const getUserAction = (id) => async (dispatch) => {
  const user = await userApi.getUser(dispatch, id);
  dispatch({
    type: GET_USER,
    payload: user,
  });
};

export const updateUserAction = (user, id) => async (dispatch) => {
  const updatedUser = await userApi.updateUser(dispatch, user, id);
  dispatch({
    type: UPDATE_USER,
    payload: updatedUser,
  });
};

export const deleteUserAction = (id) => async (dispatch) => {
  await userApi.deleteUser(dispatch, id);
  dispatch({
    type: DELETE_USER,
  });
};

export const triggerPasswordResetAction = (email) => async (dispatch) => {
  await userApi.triggerResetPassword(dispatch, email);
  dispatch({
    type: PASSWORD_RESET,
  });
};

export const syncUsersAction = () => async (dispatch) => {
  await userApi.syncUsers(dispatch);
  dispatch({
    type: SYNC_USERS,
  })
}

export const getUserNotifications = (id) => async (dispatch) => {
  const notifications = await userApi.getUserNotificationHistory(dispatch, id);
  dispatch({
    type: GET_USER_NOTIFICATIONS,
    payload: notifications,
  });
};

export const getPointsSummaryAction = (userId) => async (dispatch) => {
  const summary = await userApi.getPointsSummary(dispatch, userId);
  dispatch({
    type: GET_POINTS_SUMMARY,
    payload: summary
  });
}
export const getGroupPointsBreakdownAction = (userId, groupId) => async (dispatch) => {
  const summary = await userApi.getGroupPointsBreakdown(dispatch, userId, groupId);
  dispatch({
    type: GET_GROUP_POINTS_SUMMARY,
    payload: summary
  });
}
export const addBonusPointsAction = (userId, groupId, points) => async (dispatch) => {
  const summary = await userApi.addBonusPoints(dispatch, userId, groupId, points);
  dispatch({
    type: GET_GROUP_POINTS_SUMMARY,
    payload: summary
  });
}

export const resetGroupPointsBreakdown = () => (dispatch) => dispatch({
  type: GET_GROUP_POINTS_SUMMARY,
  payload: null
})
