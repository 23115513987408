import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
    TextField,
    Button,
    Grid,
    LinearProgress,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Table,
    Checkbox,
    TableBody,
    IconButton
} from '@material-ui/core';
import {
    getGroupMembersAction,
    getPotentialGroupMembersAction,
    createGroupMemberAction,
    updateGroupOwnersAction,
    removeGroupMemberAction
  } from '../../store/actions/group.actions';

import ConfirmationModal from '../../components/ConfirmationModal';
import { useHistory } from 'react-router-dom';

import './UserTab.scss';
import { CancelOutlined, AccountCircle } from '@material-ui/icons';

const UserTab = ({
    group
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const groupMembers = useSelector((state) => state.group.groupMembers);
    const potentialMembers = useSelector((state) => state.group.potentialGroupMembers);
    const authUser = useSelector((state) => state.auth.userInfo);
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [members, setMembers] = useState([]);
    const [potentialMemberId, setPotentialMemberId] = useState('');
    const [stagedDeleteUser, setStagedDeleteUser] = useState();
  
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const updatedMembers = _.differenceWith(members, groupMembers, _.isEqual);
  
    useEffect(() => {
      const loadMembers = async () => {
        setIsLoading(true);
        await dispatch(getGroupMembersAction(group.id));
        setIsLoading(false);
      };
  
      if (!groupMembers) {
        loadMembers();
      } else {
        setMembers(_.cloneDeep(groupMembers));
      }
    }, [dispatch, groupMembers, group.id]);
  
    if (isLoading) {
      return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
    }
  
    const search = async () => {
      setIsLoading(true);
      try {
        await dispatch(getPotentialGroupMembersAction(group.id, {
          firstName,
          lastName,
          email
        }));
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }
  
    const confirmInvite = async () => {
      setIsLoading(true);
      try {
        await dispatch(createGroupMemberAction({
          groupId: group.id,
          userId: potentialMemberId,
          isOwner: false // TODO: Add logic to allow this to be set by a user that is an owner of the group or an admin
        }));
  
        await dispatch(getGroupMembersAction(group.id));
  
        await dispatch(getPotentialGroupMembersAction(group.id, {
          firstName,
          lastName,
          email
        }))
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }

    const onIsOwnerChanged = (memberId) => {
      const update = [...members];
      const member = update.find(m => m.id === memberId);
      member.isOwner = !member.isOwner;
      setMembers(update);
    }

    const saveMemberChanges = async () => {
      setIsLoading(true);
      await dispatch(updateGroupOwnersAction(updatedMembers));
      setIsLoading(false);
    };

    const stageDeleteUsers = (user) => {
      setStagedDeleteUser(user);
    };

    const removeUserFromGroup = async () => {
      setIsLoading(true);
      await dispatch(removeGroupMemberAction(stagedDeleteUser.id, stagedDeleteUser.groupId));
      setIsLoading(false);
      setStagedDeleteUser(null);
    };

    const goToUserInfo = (userId) => {
      history.push(`/groups/${group.id}/users/${userId}`)
    };
  
    return (
      <>
        <Grid className="user-tab" container spacing={4}>
          <Grid item sm={8} >
            <TableContainer component={Paper}>
              <Table size="small" padding="none">
                <TableHead className="bg-dark">
                  <TableRow>
                    <TableCell className="text-center text-white">
                      First Name
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Last Name
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Email
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Member Status
                    </TableCell>
                    <TableCell className="text-center text-white">
                      UIN
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Owner
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Invite Date
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Status
                    </TableCell>
                    <TableCell className="text-center text-white" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members?.map((member) => {
                    return (
                    <TableRow key={member.id}>
                      <TableCell className="text-center px-2">
                        {member.firstName}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.lastName}
                      </TableCell>
                      <TableCell className="text-center px-2 email">
                        {member.email}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.memberStatus}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.uin}
                      </TableCell>
                      <TableCell>
                        <Checkbox checked={member.isOwner} onChange={() => onIsOwnerChanged(member.id)} disabled={!authUser || !authUser.isAdmin} />
                      </TableCell>
                      <TableCell>
                        {new Date(member.createdDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {member.memberStatus}
                      </TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => goToUserInfo(member.oktaUserId)}><AccountCircle /></IconButton>
                        <IconButton color="secondary" onClick={() => stageDeleteUsers(member)}><CancelOutlined /></IconButton>
                      </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
            {!!updatedMembers.length && (
              <div className="d-flex">
                <Button color="primary" onClick={saveMemberChanges}>
                  Apply Changes
                </Button>
              </div>
            )}
          </Grid>
          <Grid item sm={4} >
            <TextField
              id="filter-firstName"
              label="First Name"
              type="string"
              className="mb-4"
              value={firstName}
              fullWidth
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
            <TextField
              id="filter-lastName"
              label="Last Name"
              type="string"
              className="mb-4"
              value={lastName}
              fullWidth
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
            <TextField
              id="filter-email"
              label="Email"
              type="string"
              className="mb-4"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <Button variant="contained" color="primary" onClick={search}>
                Search
            </Button>
            <br/>
            <br/>
            <br/>
            <TableContainer component={Paper} hidden={!potentialMembers || !potentialMembers.length}>
              <Table size="small" padding="none">
                <TableHead className="bg-dark">
                  <TableRow>
                    <TableCell className="text-center text-white">
                      First Name
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Last Name
                    </TableCell>
                    <TableCell className="text-center text-white">
                      Email
                    </TableCell>
                    <TableCell className="text-center text-white">
                      UIN
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {potentialMembers?.map((member) => (
                    <TableRow>
                      <TableCell className="text-center px-2">
                        {member.firstName}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.lastName}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.email}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {member.uin}
                      </TableCell>
                      <TableCell>
                        <button
                          onClick={() => {
                            setPotentialMemberId(member.id);
                            setShowConfirmationModal(true);
                          }}
                        >
                          Invite
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <ConfirmationModal
            show={showConfirmationModal}
            onConfirm={() => {
              confirmInvite();
              setShowConfirmationModal(false)
            }}
            onCancel={() => {
              setPotentialMemberId('');
              setShowConfirmationModal(false)
            }}
            headerText="Invite Member"
            bodyText="Are you sure you want to invite this user?"
          />
          <ConfirmationModal
            show={!!stagedDeleteUser}
            onConfirm={removeUserFromGroup}
            onCancel={() => setStagedDeleteUser(null)}
            headerText={`Remove ${stagedDeleteUser?.firstName} from the group?`}
            bodyText="Are you sure you want to remove this user?"
          />
      </>
    );
}

export default UserTab;
