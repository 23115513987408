import axios from 'axios';
import { API_ERROR, CLEAR_API_ERROR } from '../store/types';
import { DEFAULT_TIMEOUT } from '../constants';

let _apiAccessTokenFunction = async () => { return ""; }

const setGetAccessTokenFunction = (apiAccessTokenFunction) => {
  _apiAccessTokenFunction = apiAccessTokenFunction;
}


const adminApi = async (
  dispatch,
  method,
  url,
  data,
  params,
  additionalHeaders
) => {
  try {
    const accessToken = await _apiAccessTokenFunction();
    const headers = {
      ...additionalHeaders,
      authorization: `Bearer ${accessToken}`,
    };
    const response = await axios({
      method,
      url,
      data,
      params,
      headers,
    });
    return response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    dispatch({
      type: API_ERROR,
      payload: err.message || err,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_API_ERROR,
      });
    }, DEFAULT_TIMEOUT);
    return null;
  }
};

const suppressApiErrors = () => {};

export default {
  setGetAccessTokenFunction,
  adminApi,
  suppressApiErrors,
};
