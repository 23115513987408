import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';
import { jsonToCSV } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import reportsApi from '../../api/reports.api';
import EventSearch from '../../components/EventSearch';

const ReportTab = ({group}) => {
  const [reportType, setReportType] = useState('summary');
  const [isSearchingEvents, setIsSearchingEvents] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [event, setEvent] = useState(null);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  const selectEvent = (e) => {
    setEvent(e);
    setIsSearchingEvents(false);
  };

  const generateReport = async () => {
    setIsGeneratingReport(true);
    try {
    let response;
    switch (reportType) {
      case 'summary':
        response = await reportsApi.getSummaryReport(dispatch, group.id);
        break;
      case 'activity':
        response = await reportsApi.getActivityReport(dispatch, group.id, event?.id);
        break;
    }
    const csvData = jsonToCSV(response, { quotes: true });
    const encodedUri = `data:text/csv;charset=UTF-8\uFEFF,${encodeURIComponent(csvData)}`;
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${reportType} report.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); 
  } catch (err) {
    console.error('unable to generate report', err);
  }

  setIsGeneratingReport(false);
  };

  return (
    <div className="report-tab">
        <Grid item sm={3} />
        <Grid item sm={6}>
          <h2>Reporting</h2>
          <div>
            <FormControl fullWidth>
              <InputLabel id="report-type-label">Report Type</InputLabel>
              <Select
                labelId="report-type-label"
                id="report-type"
                value={reportType}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value={'summary'}>Summary</MenuItem>
                <MenuItem value={'activity'}>Activity</MenuItem>
              </Select>
            </FormControl>
            {reportType === 'activity' && (
              
              <div className="d-flex align-items-center my-2">
                {event ? (
                  <>
                    <div>Report for {event.name}</div>
                    <Close color="secondary" onClick={() => setEvent(null)} />
                    </>
                ) : (
                  <Button className="mr-4" color="primary" variant="outlined" onClick={() => setIsSearchingEvents(true)}>Select Event (optional)</Button>
                  )}
                <EventSearch show={isSearchingEvents} onHide={() => setIsSearchingEvents(false)} selectEvent={selectEvent} groupId={group.id} />
                </div>
            )}
          </div>
          <div className="mt-4">
            <Button color="primary" variant="contained" onClick={generateReport}>
              {isGeneratingReport ? <CircularProgress size={30} color="secondary" /> : "Download"}
            </Button>
          </div>
        </Grid>
    </div>
  )
}

export default ReportTab;
