import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';

// This in theory should be a container, but it's so small that I'm going to leave it here
const Error = () => {
  const error = useSelector((state) => state.error.error);
  console.log('error', error);
  if (!error) {
    return <></>;
  }
  return <Alert severity="error">{error}</Alert>;
};

export default Error;
