import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Toolbar,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import { Menu, AccountCircle, Group, Event } from '@material-ui/icons';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import { setUserInfo } from '../store/actions/auth.actions';

const STUCK_IN_PENDING_LIMIT = 5000;

let timeout;

const AdminNavBar = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.userInfo);
  const { authService, authState } = useOktaAuth();
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const login = async () => authService.login('/groups');
  const logout = async () =>
    authService.logout();
  const toggleMenu = () => setShowMenu(!showMenu);
  const goTo = (route) => {
    history.push(route);
    toggleMenu();
  };

  useEffect(() => {
    if (!authState.isPending && authState.isAuthenticated) {
      api.setGetAccessTokenFunction(authService.getAccessToken);
      authService.getUser().then((userInfo) => {
        // Set the user info in state somewhere
        dispatch(setUserInfo({
          isAdmin: !!userInfo.groups && userInfo.groups.includes('Admin'),
          name: userInfo.name
        }));
      });
    }
    
  }, [authState, authService, dispatch]);

  const renderLoginButton = () => {
    if (authState.isPending) {
      timeout = setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, STUCK_IN_PENDING_LIMIT);
      return <CircularProgress color="secondary" />;
    }
    if (timeout) {
      clearTimeout(timeout);
    }

    if (authState.isAuthenticated) {
      return (
        <Button variant="contained" color="secondary" onClick={logout}>
          Sign out
        </Button>
      );
    }
    return (
      <Button variant="contained" color="secondary" onClick={login}>
        Sign in
      </Button>
    );
  };

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" className="flex-grow-1">
            Okta React Admin
          </Typography>
          {renderLoginButton()}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={showMenu} onClose={toggleMenu}>
        {authState.isAuthenticated && (
          <List>
            {authUser && authUser.isAdmin && (
            <ListItem button onClick={() => goTo('/users')}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            )}
            <ListItem button onClick={() => goTo('/groups')}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary="Groups" />
            </ListItem>
            <ListItem button onClick={() => goTo('/events')}>
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <ListItemText primary="Events" />
            </ListItem>
            <ListItem button onClick={() => goTo('/semester')}>
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <ListItemText primary="Semester" />
            </ListItem>
            <ListItem button onClick={() => goTo('/reports')}>
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
          </List>
        )}
      </Drawer>
    </>
  );
};

export default AdminNavBar;
