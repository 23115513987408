import { SET_SEMESTER_DATA } from "../types";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SEMESTER_DATA:
      return {
        semester: action.payload,
      };
    default:
      return state;
  }
};
