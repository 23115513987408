import userApi from '../../api/user.api';
import groupApi from '../../api/group.api';
import groupMemberApi from '../../api/groupMember.api';
import { MAX_RESULTS } from '../constants';

import {
  GET_GROUPS,
  GET_GROUPS_LOOKUP,
  GET_GROUP,
  UPDATE_GROUP,
  CREATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_INVITE_CODES,
  CREATE_GROUP_INVITE_CODE,
  SELECT_GROUP_INVITE_CODE,
  UPDATE_GROUP_INVITE_CODE,
  GET_GROUP_MEMBERS,
  GET_POTENTIAL_MEMBERS,
  CREATE_GROUP_MEMBER
} from '../types';

export const createGroupAction = (data) => async (dispatch) => {
  const group = await groupApi.createGroup(dispatch, data);
  dispatch({
    type: CREATE_GROUP,
    payload: group,
  });
  return group;
};

export const getGroupsAction = (params) => async (dispatch) => {
  const groups = await groupApi.getGroups(dispatch, params);
  dispatch({
    type: GET_GROUPS,
    payload: groups,
  });
};

export const getGroupsLookup = () => async (dispatch) => {
  const groups = await groupApi.getGroups(dispatch, {skip: 0, limit: MAX_RESULTS});
  dispatch({
    type: GET_GROUPS_LOOKUP,
    payload: groups ? groups.data : []
  });
}

export const getGroupAction = (id) => async (dispatch) => {
  const group = await groupApi.getGroup(dispatch, id);
  dispatch({
    type: GET_GROUP,
    payload: group,
  });
};

export const updateGroupAction = (group, id) => async (dispatch) => {
  const updatedGroup = await groupApi.updateGroup(dispatch, group, id);
  dispatch({
    type: UPDATE_GROUP,
    payload: updatedGroup,
  });
  return updatedGroup;
};

export const deleteGroupAction = (id) => async (dispatch) => {
  await groupApi.deleteGroup(dispatch, id);
  dispatch({
    type: DELETE_GROUP,
  });
};

export const getGroupInviteCodesAction = (groupId, params) => async (dispatch) => {
  const groups = await groupApi.getGroupInviteCodes(dispatch, groupId, params);
  dispatch({
    type: GET_GROUP_INVITE_CODES,
    payload: groups,
  });
};

export const createGroupInviteCodeAction = (groupId) => async (dispatch) => {
  const inviteCode = await groupApi.createGroupInviteCode(dispatch, groupId);
  dispatch({
    type: CREATE_GROUP_INVITE_CODE,
    payload: inviteCode,
  });
  return inviteCode;
};

export const updateGroupInviteCodeAction = (groupId, inviteCode) => async (dispatch) => {
  const updatedInviteCode = await groupApi.updateGroupInviteCode(dispatch, groupId, inviteCode);
  dispatch({
    type: UPDATE_GROUP_INVITE_CODE,
    payload: updatedInviteCode,
  });
  return updatedInviteCode;
};

export const selectGroupInviteCode = (inviteCode) => (dispatch) => {
  dispatch({
    type: SELECT_GROUP_INVITE_CODE,
    payload: inviteCode,
  });
};

export const getGroupMembersAction = (groupId) => async (dispatch) => {
  const response = await groupMemberApi.getGroupMembers(dispatch, groupId);
  dispatch({
    type: GET_GROUP_MEMBERS,
    payload: response?.data ?? null,
  });
};

export const getPotentialGroupMembersAction = (groupId, filter = {}) => async (dispatch) => {
  const response = await userApi.getUsers(dispatch, { filter: { notInGroupId: groupId, ...filter } });

  dispatch({
    type: GET_POTENTIAL_MEMBERS,
    payload: response?.data ?? null,
  });
};

export const createGroupMemberAction = (data) => async (dispatch) => {
  const newMember = await groupMemberApi.createGroupMember(dispatch, data);

  dispatch({
    type: CREATE_GROUP_MEMBER,
    payload: newMember
  });
};

export const updateGroupOwnersAction = (data) => async (dispatch) => {
  const response = await groupMemberApi.updateGroupMemberOwners(dispatch, data);
  dispatch({
    type: GET_GROUP_MEMBERS,
    payload: response?.data ?? null,
  });
}

export const bulkAttendEventsAction = (groupId, data) => async (dispatch) => {
  return groupApi.bulkAttendEvents(dispatch, groupId, data);
}

export const removeGroupMemberAction = (id, groupId) => async (dispatch) => {
  await groupMemberApi.removeGroupMember(dispatch, id);
  await dispatch(getGroupMembersAction(groupId));
}
