import {
  GET_GROUPS,
  GET_GROUPS_LOOKUP,
  GET_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_INVITE_CODES,
  CREATE_GROUP_INVITE_CODE,
  UPDATE_GROUP_INVITE_CODE,
  SELECT_GROUP_INVITE_CODE,
  GET_GROUP_MEMBERS,
  GET_POTENTIAL_MEMBERS
} from '../types';

const initialState = {
  group: null,
  groupMembers: null,
  potentialGroupMembers: null,
  groups: null,
  groupsLookup: null,
  groupInviteCode: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS_LOOKUP:
      return {
        ...state,
        groupsLookup: action.payload
      };
    case GET_GROUPS:
      return {
        groups: action.payload,
      };
    case GET_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        group: action.payload.updated,
      };
    case DELETE_GROUP:
      return {
        ...state,
        group: null,
      };
    case GET_GROUP_INVITE_CODES: 
      return {
        ...state,
        groupInviteCodes: action.payload
      };
    case CREATE_GROUP_INVITE_CODE: 
      return {
        ...state,
        groupInviteCode: action.payload
      };
    case UPDATE_GROUP_INVITE_CODE: 
      return {
        ...state,
        groupInviteCode: action.payload
      };
    case SELECT_GROUP_INVITE_CODE: {
      return {
        ...state,
        groupInviteCode: action.payload
      };
    }
    case GET_GROUP_MEMBERS: {
      return {
        ...state,
        groupMembers: action.payload
      };
    }
    case GET_POTENTIAL_MEMBERS: {
      return {
        ...state,
        potentialGroupMembers: action.payload
      }
    }
    default:
      return state;
  }
};
