import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  LinearProgress,
  Typography,
  Tooltip,
  Checkbox,
  MenuItem,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  ListItemText,
  FormHelperText,
} from '@material-ui/core';
import {
  CloudUpload
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import _ from 'lodash';
import {
  getEventAction,
  updateEventAction,
  createEventAction,
  deleteEventAction,
} from '../store/actions/event.actions';
import ConfirmationModal from '../components/ConfirmationModal';
import { parseISO, formatISO, isValid, isBefore } from 'date-fns'

import './Event.scss';
import { getGroupsLookup } from '../store/actions/group.actions';
import {
  getImageUploadTokenAction
} from '../store/actions/imageUpload.actions';

import { BlockBlobClient } from '@azure/storage-blob';
import { MS_PER_MINUTE, ZIP_LIMIT, THEME_LIMIT, TINY_TEXT_LIMIT, SMALL_TEXT_LIMIT, STANDARD_TEXT_LIMIT, LARGE_TEXT_LIMIT, EXTRA_LARGE_TEXT_LIMIT, MAX_TEXT_LIMIT } from '../constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const parseStringIntoArray = (string, split = ',') => {
  if (_.isArray(string)) {
    return string;
  }

  if (!string) {
    return [];
  } else {
    return string.split(split);
  }
}

const Event = () => {
  const params = useParams();
  const event = useSelector((state) => state.event.event);
  const groupsLookup = useSelector((state) => {
    const currentGroups = !!state.group?.groupsLookup ? state.group.groupsLookup : [];

    return [{ id: '', name: '<select a group>' }, ...currentGroups];
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [newEvent, setNewEvent] = useState({
    groupId: '',
    name: '',
    owner: '',
    endUser: [],
    website: '',
    fieldOfFocus: [],
    additionalInfo: '',
    category: '',
    subCategory: [],
    contact: {
      prefix: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    headerPhotoUrl: '',
    attendeePoints: 100,
    startTime: new Date(),
    endTime: new Date(),
    lastCheckInTime: new Date(),
    location: {
      latitude: 30.6187558,
      longitude: -96.3386659
    },
    locationRadius: 30,
    minimumThresholdTimeSpan: 50,
    approvalStatus: "APPROVED",
    publishFrom: new Date(),
    publishTo: new Date(),
    type: [],
    department: '',
    accessInfo: '',
    rsvpLink: '',
    meetingLink: '',
    campus: false,
    locationDescription: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    building: '',
    locationMapUrl: '',
    theme: [],
    groupNotes: '',
    adminNotes: '',
    archive: false,
  });
  // Description needs to remain it's own thing because of the
  // ReactQuill lifecycle
  const [description, setDescription] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [imageUploadError, setImageUploadError] = useState('');
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isVirtualEvent, setIsVirtualEvent] = useState(false);

  const isCreating = params.id.toLowerCase() === 'create';

  useEffect(() => {
    const loadEvent = async () => {
      setIsLoading(true);
      if (!isCreating) {
        await dispatch(getEventAction(params.id));
      }
      await dispatch(getGroupsLookup());
      setIsLoading(false);
    };
    if (event && event.id === params.id) {
      const currentEvent = {
        ...(_.cloneDeep(event)),
        startTime: parseISO(event.startTime),
        endTime: parseISO(event.endTime),
        lastCheckInTime: parseISO(event.lastCheckInTime),
        minimumThresholdTimeSpan: event.minimumThresholdTimeSpan / MS_PER_MINUTE,
        publishFrom: event.publishFrom ? parseISO(event.publishFrom) : undefined,
        publishTo: event.publishTo ? parseISO(event.publishTo) : undefined,
        type: parseStringIntoArray(event.type),
        theme: parseStringIntoArray(event.theme),
        fieldOfFocus: parseStringIntoArray(event.fieldOfFocus),
        endUser: parseStringIntoArray(event.endUser),
        subCategory: parseStringIntoArray(event.subCategory)
      };

      // 0 is a valid lat/long, so you have to do a hard null check here
      if (event.location.longitude == null && event.location.latitude == null) setIsVirtualEvent(true);
      setNewEvent(currentEvent);
      setDescription(event.description);
    } else {
      loadEvent();
    }
  }, [dispatch, isCreating, params.id, event]);
  if (!params.id) {
    return <Redirect to="/events" />;
  }
  const saveEvent = async () => {
    setHasSubmitted(true);
    if (
      !newEvent.name ||
      !description ||
      !newEvent.groupId ||
      !newEvent.category ||
      !newEvent.subCategory.length ||
      !newEvent.website ||
      !newEvent.fieldOfFocus.length ||
      !newEvent.endUser.length ||
      (newEvent.startTime != null && !isValid(newEvent.startTime)) ||
      (newEvent.endTime != null && !isValid(newEvent.endTime)) ||
      (newEvent.lastCheckInTime != null && !isValid(newEvent.lastCheckInTime)) ||
      !newEvent.type.length
    ) {
      return;
    }

    const data = {
      ...(_.cloneDeep(newEvent)),
      description,
      points: newEvent.points,
      startTime: newEvent.startTime != null ? formatISO(newEvent.startTime) : null,
      endTime: newEvent.endTime != null ? formatISO(newEvent.endTime) : null,
      lastCheckInTime: newEvent.lastCheckInTime != null ? formatISO(newEvent.lastCheckInTime) : null,
      minimumThresholdTimeSpan: newEvent.minimumThresholdTimeSpan != null ? newEvent.minimumThresholdTimeSpan * MS_PER_MINUTE : null
    };

    if (isVirtualEvent) {
      delete data.location;
      delete data.locationRadius;
    }

    setIsLoading(true);
    let result = null;
    if (isCreating) {
      result = await dispatch(createEventAction(data));
    } else {
      result = await dispatch(updateEventAction(data, event.id));
    }

    setIsLoading(false);
    if (result) {
      history.push('/events');
    }
  };
  const deleteEvent = () => {
    dispatch(deleteEventAction(event.id));
    history.push('/events');
  };

  const handleFileSelected = async ({ target }) => {
    try {
      setIsImageUploading(true);
      const imageUploadToken = await dispatch(getImageUploadTokenAction());
      if (!imageUploadToken) {
        setImageUploadError('Error uploading image');
      } else {
        const blobClient = new BlockBlobClient(imageUploadToken.uri);
        await blobClient.uploadBrowserData(target.files[0]);
        onChange('headerPhotoUrl', imageUploadToken.cdnUri, { limit: EXTRA_LARGE_TEXT_LIMIT });
        setImageUploadError('');
      }
    } catch (err) {
      setImageUploadError('Error uploading image');
    } finally {
      target.value = null;
      setIsImageUploading(false);
    }

  };

  const onChange = (key, value, params = { limit: STANDARD_TEXT_LIMIT }) => {
    const unUpdatedEvent = _.cloneDeep(newEvent);

    if (params?.limit !== null && value.length > params.limit) {
      return;
    }

    switch (key) {
      case 'contact_firstName':
      case 'contact_lastName':
      case 'contact_prefix':
      case 'contact_phoneNumber':
      case 'contact_email':
        const contactKey = key.replace('contact_', '');

        unUpdatedEvent.contact[contactKey] = value;
        break;
      case 'virtual':
        if (!value && unUpdatedEvent.location.latitude == null && unUpdatedEvent.location.longitude == null) {
          unUpdatedEvent.location = {
            latitude: 30.6187558,
            longitude: -96.3386659
          };
          unUpdatedEvent.locationRadius = 30;
        }

        setIsVirtualEvent(value);
        break;
      default:
        unUpdatedEvent[key] = value;
        break;
    }

    setNewEvent(unUpdatedEvent);
  };

  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }
  return (
    <Grid container className="event">
      <Grid item sm={3} />
      <Grid item sm={6}>
        <h2>Event</h2>
        <TextField
          id="event-name"
          label="Title"
          type="string"
          className="mb-4"
          value={newEvent.name}
          error={hasSubmitted && !newEvent.name}
          helperText={hasSubmitted && !newEvent.name && "Title is required"}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('name', e.currentTarget.value, { limit: SMALL_TEXT_LIMIT })}
        />
        <label className="text-muted">Description</label>
        <ReactQuill placeholder="Description" className="mb-4" value={description} onChange={(v) => setDescription(v)} />
        <TextField
          id="event-additionalInfo"
          label="Additional Info (Optional)"
          type="string"
          className="mb-4"
          value={newEvent.additionalInfo}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('additionalInfo', e.currentTarget.value, { limit: MAX_TEXT_LIMIT })}
        />
        <FormControl
          fullWidth
          variant="outlined"
          className="mb-4"
          error={hasSubmitted && !newEvent.fieldOfFocus.length}
        >
          <InputLabel id="select-multiple-fieldOfFocus-label">
            Field of Focus
          </InputLabel>
          <Select
            id="select-multiple-fieldOfFocus"
            labelId="select-multiple-fieldOfFocus-label"
            label="Field of Focus"
            multiple
            value={newEvent.fieldOfFocus}
            onChange={(e) => onChange('fieldOfFocus', e.target.value, { limit: LARGE_TEXT_LIMIT })}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Innovation', 'Creativity', 'Leadership', 'Entrepreneurship', 'Other'].map((fieldOfFocus) => (
            <MenuItem key={fieldOfFocus} value={fieldOfFocus}>
              <Checkbox checked={newEvent.fieldOfFocus.indexOf(fieldOfFocus) > -1} />
              <ListItemText primary={fieldOfFocus} />
            </MenuItem>
            ))}
          </Select>
          {hasSubmitted && !newEvent.fieldOfFocus.length && <FormHelperText>Field of focus is required</FormHelperText>}
        </FormControl>
        <TextField
          id="event-website"
          label="Website"
          type="string"
          className="mb-4"
          value={newEvent.website}
          error={hasSubmitted && !newEvent.website}
          helperText={hasSubmitted && !newEvent.website && "Website is required"}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('website', e.currentTarget.value)}
        />
        <TextField
          select
          label="Category"
          className="mb-4"
          fullWidth
          variant="outlined"
          value={newEvent.category}
          error={hasSubmitted && !newEvent.category}
          helperText={hasSubmitted && !newEvent.category && "Category is required"}
          onChange={(e) => onChange('category', e.target.value, { limit: SMALL_TEXT_LIMIT })}
          inputProps={{
            id: 'select-multiple-category',
          }}
        >
          {['Centers and Programs', 'Courses', 'Events', 'Research', 'Resources and Tools', 'Student Organizations'].map((category) => (
          <MenuItem key={category} value={category}>
            <ListItemText primary={category} />
          </MenuItem>
          ))}
        </TextField>
        <FormControl
          fullWidth
          variant="outlined"
          className="mb-4"
          error={hasSubmitted && !newEvent.subCategory.length}
        >
          <InputLabel id="select-multiple-subCategory-label">
            Subcategory
          </InputLabel>
          <Select
            id="select-multiple-subCategory"
            labelId="select-multiple-subCategory-label"
            label="Subcategory"
            multiple
            value={newEvent.subCategory}
            onChange={(e) => onChange('subCategory', e.target.value, { limit: SMALL_TEXT_LIMIT })}
            renderValue={(selected) => selected.join(', ')}
          >
            {['1 on 1 Session', 'Accelerator/Incubator', 'Career Exploration and Networking', 'Ceremony/Tradition', 'Class', 'Collaborative Space', 'Competition/Contest', 'Conference/Symposium', 'Exhibit', 'Fair/Festival', 'Film/Entertainment', 'Fundraiser', 'Hackathon/Ideation Session', 'Information Session', 'Laboratory', 'Lecture', 'Living Learning', 'Makerspace', 'Meal', 'Meeting', 'Mentorship Opportunity', 'Online Tool or Resource', 'Open Event', 'Organization/Office', 'Party', 'Performance', 'Projects to Join', 'Reception', 'Social', 'Speaker', 'Study Space', 'Technology/Tools', 'Training/Workshop', 'Volunteer Opportunity'].map((subCategory) => (
            <MenuItem key={subCategory} value={subCategory}>
              <Checkbox checked={newEvent.subCategory.indexOf(subCategory) > -1} />
              <ListItemText primary={subCategory} />
            </MenuItem>
            ))}
          </Select>
          {hasSubmitted && !newEvent.subCategory.length && <FormHelperText>Subcategory is required</FormHelperText>}
        </FormControl>
        <TextField className="mb-4" label="Contact Prefix" type="text" value={newEvent.contact.prefix} fullWidth variant="outlined" onChange={(e) => onChange('contact_prefix', e.currentTarget.value, { limit: TINY_TEXT_LIMIT })} />
        <TextField
          id="event-contactFirstName"
          label="Contact First Name"
          type="string"
          className="mb-4"
          value={newEvent.contact.firstName}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('contact_firstName', e.currentTarget.value)}
        />
        <TextField
          id="event-contactLastName"
          label="Contact Last Name"
          type="string"
          className="mb-4"
          value={newEvent.contact.lastName}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('contact_lastName', e.currentTarget.value)}
        />
        <TextField className="mb-4" label="Contact Phone" type="text" value={newEvent.contact.phoneNumber} fullWidth variant="outlined" onChange={(e) => onChange('contact_phoneNumber', e.currentTarget.value)} />
        <TextField className="mb-4" label="Contact Email" type="text" value={newEvent.contact.email} fullWidth variant="outlined" onChange={(e) => onChange('contact_email', e.currentTarget.value)} />
        {newEvent.headerPhotoUrl && <img style={{ maxWidth: 150 }} src={newEvent.headerPhotoUrl} alt="Event Header" />}
        <Typography variant="subtitle1">Header Photo</Typography>
        <input
          accept="image/jpeg"
          style={{ display: 'none' }}
          id="headerPhoto"
          type="file"
          onChange={handleFileSelected}
        />

        <Tooltip title="Select Image">
          <label htmlFor="headerPhoto">
            <Button
              variant="contained"
              disabled={isImageUploading}
              color="primary"
              aria-label="upload header photo"
              component="span"
              startIcon={<CloudUpload />}>
              Upload Image
            </Button>
          </label>
        </Tooltip>
        {isImageUploading && <LinearProgress style={{ height: '1rem' }} color="secondary" />}
        {imageUploadError && <Typography variant="body2" color="error">{imageUploadError}</Typography>}
        <br/>
        <br/>
        <Autocomplete
          id="event-group"
          className={hasSubmitted && !newEvent.groupId && "error"}
          options={groupsLookup}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value}
          style={{ width: '100%' }}
          required
          value={newEvent.groupId ? groupsLookup.find(group => group.id === newEvent.groupId) : groupsLookup[0]}
          onChange={(_, newValue) => onChange('groupId', newValue ? newValue.id : '', { limit: null })}
          renderInput={(params) => <TextField {...params} label="Group" inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}variant="outlined" />}
        />
        {hasSubmitted && !newEvent.groupId && <Typography style={{marginLeft: 14}} variant="body2" color="error">You must select a group</Typography>}
        <br />
        <TextField
          id="event-description"
          label="Attendee Points"
          type="number"
          min="1"
          className="mb-4"
          value={newEvent.attendeePoints}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('attendeePoints', e.currentTarget.value, { limit: null })}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDateTimePicker
            variant="inline"
            ampm={true}
            label="Start time"
            value={newEvent.startTime}
            onChange={(date) => onChange('startTime', date, { limit: null })}
            error={hasSubmitted && (newEvent.startTime != null && !isValid(newEvent.startTime))}
            helperText={hasSubmitted && (newEvent.startTime != null && !isValid(newEvent.startTime)) && "A valid start time is required"}
            onError={console.log}
            disablePast
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <br />
        <br />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDateTimePicker
            variant="inline"
            ampm={true}
            label="End Time"
            value={newEvent.endTime}
            error={hasSubmitted && newEvent.endTime != null && (!isValid(newEvent.endTime) || isBefore(newEvent.endTime, newEvent.startTime))}
            helperText={hasSubmitted && newEvent.endTime != null && (!isValid(newEvent.endTime) || isBefore(newEvent.endTime, newEvent.startTime)) && "A valid end time is required and must be after the start time"}
            onChange={(date) => onChange('endTime', date, { limit: null })}
            onError={console.log}
            disablePast
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <br />
        <br />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDateTimePicker
            variant="inline"
            ampm={true}
            label="Last Checkin Date"
            value={newEvent.lastCheckInTime}
            onChange={(date) => onChange('lastCheckInTime', date, { limit: null })}
            onError={console.log}
            disablePast
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <br/>
        <br />
        <TextField
          id="event-min-threshold"
          label="Duration to attain points (minutes)"
          type="number"
          min="1"
          className="mb-4"
          value={newEvent.minimumThresholdTimeSpan}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('minimumThresholdTimeSpan', e.currentTarget.value, { limit: null })}
        />
        <br/>
        <br />
        <TextField
          id="event-approval-approval"
          label="Approval Status"
          select
          className="mb-4"
          value={newEvent.approvalStatus}
          fullWidth
          variant="outlined"
          onChange={(e) => onChange('approvalStatus', e.target.value, { limit: null })}
          >
            <MenuItem value="APPROVED">
              Approved
            </MenuItem>
            <MenuItem value="PENDING">
              Pending
            </MenuItem>
            <MenuItem value="REJECTED">
              Rejected
            </MenuItem>
          </TextField>
        <br/>
        <FormControlLabel
          control={
            <Checkbox 
              checked={isVirtualEvent}
              onChange={(e) => onChange('virtual', e.target.checked, { limit: null })}
              name="isVirtualEvent"
              color="primary"
            />}
          label="Virtual event"
        />
        <br/>
        <br/>
        {!isVirtualEvent && (
        <>
          <TextField
            label="Latitude"
            type="number"
            min="1"
            className="mb-4"
            value={newEvent.location.latitude}
            fullWidth
            variant="outlined"
            onChange={(e) => onChange('location', {...newEvent.location, latitude: e.currentTarget.value}, { limit: null })}
          />
          <TextField
            label="Longitude"
            type="number"
            min="1"
            className="mb-4"
            value={newEvent.location.longitude}
            fullWidth
            variant="outlined"
            onChange={(e) => onChange('location', {...newEvent.location, longitude: e.currentTarget.value}, { limit: null })}
          />
          <TextField
            label="Location Radius (ft)"
            type="number"
            min="1"
            className="mb-4"
            value={newEvent.locationRadius}
            fullWidth
            variant="outlined"
            onChange={(e) => onChange('locationRadius', e.currentTarget.value, { limit: null })}
          />
        </>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            fullWidth
            variant="inline"
            className="mb-4"
            ampm={true}
            label="Publish From"
            value={newEvent.publishFrom}
            onChange={(date) => onChange('publishFrom', date, { limit: null })}
            onError={console.log}
            disablePast
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
          fullWidth
            variant="inline"
            ampm={true}
            className="mb-4"
            label="Publish To"
            value={newEvent.publishTo}
            onChange={(date) => onChange('publishTo', date, { limit: null })}
            onError={console.log}
            disablePast
            format="MM/dd/yyyy hh:mm a"
          />
        </MuiPickersUtilsProvider>
        <FormControl
          fullWidth
          className="mb-4"
          variant="outlined"
          error={hasSubmitted && !newEvent.type.length}
        >
          <InputLabel id="select-multiple-type-label">
            Type
          </InputLabel>
          <Select
            multiple
            label="Type"
            labelId="select-multiple-type-label"
            value={newEvent.type}
            onChange={(e) => onChange('type', e.target.value, { limit: TINY_TEXT_LIMIT })}
            id='select-multiple-type'
            renderValue={(selected) => selected.join(', ')}
          >
            {['In-Person Event', 'Virtual Event', 'Resource', 'Anytime Activity'].map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={newEvent.type?.indexOf(type) > -1} />
              <ListItemText primary={type} />
            </MenuItem>
            ))}
          </Select>
          {hasSubmitted && !newEvent.type.length && <FormHelperText>Type is required</FormHelperText>}
        </FormControl>
        <FormControl
          fullWidth
          className="mb-4"
          variant="outlined"
        >
          <InputLabel id="select-multiple-theme-label">
            Theme
          </InputLabel>
          <Select
            multiple
            label="Theme"
            labelId="select-multiple-theme-label"
            value={newEvent.theme}
            onChange={(e) => onChange('theme', e.target.value, { limit: THEME_LIMIT })}
            id='select-multiple-theme'
            renderValue={(selected) => selected.join(', ')}
          >
            {['Arts & Music','Athletics','Cultural','Diversity','Fundraising','Group Business','Health/Exercise','Learning','Service'].map((theme) => (
            <MenuItem key={theme} value={theme}>
              <Checkbox checked={newEvent.theme?.indexOf(theme) > -1} />
              <ListItemText primary={theme} />
            </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          className="mb-4"
          error={hasSubmitted && !newEvent.endUser.length}
        >
          <InputLabel id="select-multiple-user-label">
            User
          </InputLabel>
          <Select
            multiple
            label="User"
            labelId="select-multiple-user-label"
            value={newEvent.endUser}
            onChange={(e) => onChange('endUser', e.target.value)}
            inputProps={{
              id: 'select-multiple-endUser',
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Faculty', 'Former Students and Friends', 'Grad/Prof Students', 'PostDocs', 'Staff', 'Undergraduate Students'].map((endUser) => (
            <MenuItem key={endUser} value={endUser}>
              <Checkbox checked={newEvent.endUser?.indexOf(endUser) > -1} />
              <ListItemText primary={endUser} />
            </MenuItem>
            ))}
          </Select>
          {hasSubmitted && !newEvent.endUser.length && <FormHelperText>End user is required</FormHelperText>}
        </FormControl>
        <TextField className="mb-4" label="Owner" type="text" value={newEvent.owner} fullWidth variant="outlined" onChange={(e) => onChange('owner', e.currentTarget.value)} />
        <TextField className="mb-4" label="Department" type="text" value={newEvent.department} fullWidth variant="outlined" onChange={(e) => onChange('department', e.currentTarget.value)} />
        <TextField className="mb-4" label="RSVP Link" type="text" value={newEvent.rsvpLink} fullWidth variant="outlined" onChange={(e) => onChange('rsvpLink', e.currentTarget.value)} />
        <TextField className="mb-4" label="Meeting Link" type="text" value={newEvent.meetingLink} fullWidth variant="outlined" onChange={(e) => onChange('meetingLink', e.currentTarget.value)} />
        <TextField className="mb-4" label="Location Description" type="text" value={newEvent.locationDescription} fullWidth variant="outlined" onChange={(e) => onChange('locationDescription', e.currentTarget.value, { limit: SMALL_TEXT_LIMIT })} />
        <TextField className="mb-4" label="Address" type="text" value={newEvent.address1} fullWidth variant="outlined" onChange={(e) => onChange('address1', e.currentTarget.value)} />
        <TextField className="mb-4" label="Address2" type="text" value={newEvent.address2} fullWidth variant="outlined" onChange={(e) => onChange('address2', e.currentTarget.value)} />
        <TextField className="mb-4" label="City" type="text" value={newEvent.city} fullWidth variant="outlined" onChange={(e) => onChange('city', e.currentTarget.value)} />
        <TextField className="mb-4" label="State" type="text" value={newEvent.state} fullWidth variant="outlined" onChange={(e) => onChange('state', e.currentTarget.value)} />
        <TextField className="mb-4" label="Country" type="text" value={newEvent.country} fullWidth variant="outlined" onChange={(e) => onChange('country', e.currentTarget.value)} />
        <TextField className="mb-4" label="Zip" type="text" value={newEvent.zip} fullWidth variant="outlined" onChange={(e) => onChange('zip', e.currentTarget.value, { limit: ZIP_LIMIT })} />
        <TextField className="mb-4" label="Building" type="text" value={newEvent.building} fullWidth variant="outlined" onChange={(e) => onChange('building', e.currentTarget.value)} />
        <TextField className="mb-4" label="Location Map" type="text" value={newEvent.locationMapUrl} fullWidth variant="outlined" onChange={(e) => onChange('locationMapUrl', e.currentTarget.value, { limit: EXTRA_LARGE_TEXT_LIMIT })} />
        <TextField className="mb-4" label="Group Notes" type="text" value={newEvent.groupNotes} fullWidth variant="outlined" onChange={(e) => onChange('groupNotes', e.currentTarget.value)} />
        <TextField className="mb-4" label="Admin Notes" type="text" value={newEvent.adminNotes} fullWidth variant="outlined" onChange={(e) => onChange('adminNotes', e.currentTarget.value)} />
        <FormControlLabel
          control={
            <Checkbox 
              checked={newEvent.archive}
              onChange={(e) => onChange('archive', e.target.checked, { limit: null })}
              name="archive"
              color="primary"
            />}
          label="Archive?"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox 
              checked={newEvent.campus}
              onChange={(e) => onChange('campus', e.target.checked, { limit: null })}
              name="campus"
              color="primary"
            />}
          label="Is on Campus?"
        />
        <div className="mt-4 text-right">
          <Button variant="contained" color="primary" onClick={saveEvent}>
            Save
          </Button>
          {!isCreating && (
            <Button
              disabled={true}
              variant="contained"
              color="secondary"
              className="ml-2"
              onClick={() => setShowConfirmationModal(true)}
            >
              Delete
            </Button>
          )}
        </div>

      </Grid>
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={deleteEvent}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete Event"
        bodyText="Are you sure you want to delete this event?"
      />
    </Grid>
  );
};

export default Event;
