import {
  GET_IMAGE_UPLOAD_TOKEN
} from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_IMAGE_UPLOAD_TOKEN:
      return {
        ...state,
        imageUploadToken: action.payload
      };
      default:
      return state;
  }
};
