import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import {
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {
  getGroupAction
} from '../../store/actions/group.actions';

import CustomTabs from '../../components/Tabs';

import './Group.scss';

import GroupTab from './GroupTab';
import UserTab from './UserTab';
import BulkPointsTab from './BulkPointsTab';
import ReportTab from './ReportTab';
import BulkEventUpload from './BulkEventUpload';

const Group = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.group.group);

  const [isLoading, setIsLoading] = useState(false);
  const isCreating = params.id.toLowerCase() === 'create';

  useEffect(() => {
    const loadGroup = async () => {
      setIsLoading(true);
      await dispatch(getGroupAction(params.id));
      setIsLoading(false);
    };

    if (!isCreating && !group) {
      loadGroup();
    }
  }, [dispatch, isCreating, params.id, group]);

  if (!params.id) {
    return <Redirect to="/groups" />;
  }

  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }

  return (
    <Grid container className="group">
      {
        isCreating ?
          <GroupTab group={group} isCreating={true} setIsLoading={setIsLoading} />
        :
          <CustomTabs 
            tabs={[
              {
                label: 'Group'
              },
              {
                label: 'Members'
              },
              {
                label: 'Upload Event Attendance'
              }, 
              {
                label: 'Reports'
              },
              {
                label: 'Bulk Event Import'
              }
            ]}
            tabItems={[
              (<GroupTab group={group} isCreating={false} setIsLoading={setIsLoading} />),
              (<UserTab group={group} />),
              (<BulkPointsTab group={group} />),
              (<ReportTab group={group} />),
              (<BulkEventUpload groupId={group?.id} />)
            ]}
          />
      }
    </Grid>
  );
};

export default Group;
