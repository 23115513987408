import { combineReducers } from 'redux';
import user from './user.reducers';
import group from './group.reducers';
import event from './event.reducers';
import imageUpload from './imageUpload.reducers';
import error from './error.reducers';
import auth from './auth.reducers';
import semester from './semester.reducers';

export default combineReducers({
  user,
  group,
  event,
  imageUpload,
  error,
  auth,
  semester
});
