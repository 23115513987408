import api from '.';


const URL = process.env.REACT_APP_API_URL;
const SEMESTER_URL = `${URL}/mbadmin/semester`;

const getSemesterData = async (dispatch) => {
  return api.adminApi(dispatch, 'get', SEMESTER_URL);
}

const updateSemesterData = async (dispatch, data) => {
  return api.adminApi(dispatch, 'post', SEMESTER_URL, data);
}

export default {
  getSemesterData,
  updateSemesterData
};

