import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import blue from '@material-ui/core/colors/blue';
import { Grid } from '@material-ui/core';
import AdminNavBar from './components/AdminNavBar';
import Users from './containers/Users';
import User from './containers/User';
import Groups from './containers/Groups';
import Group from './containers/Group/Group';
import GroupInviteCodes from './containers/GroupInviteCodes';
import Events from './containers/Events';
import Event from './containers/Event';
import Semester from './containers/Semester';
import Reports from './containers/Reports';

import Error from './components/Error';
import './App.scss';


const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});


const config = {
  issuer: process.env.REACT_APP_OKTA_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.protocol}//${window.location.host}/implicit/callback`,
  postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
  pkce: true,
  scopes: ['openid', 'profile', 'email', 'groups']
};

function App() {
  return (
    <div className="App">
      <Router>
        <Security {...config}>
          <Switch>
            <ThemeProvider theme={theme}>
              <AdminNavBar />
              <Grid style={{ marginTop: '3rem' }} container spacing={3}>
                <Grid item md={12}>
                  <Error />
                </Grid>
                <Grid item md={1} />
                <Grid item md={10}>
                  <Route
                    path="/"
                    exact
                    component={() => <div>Sign in to continue</div>}
                  />
                  <SecureRoute path="/users" exact component={Users} />
                  <SecureRoute path="/users/:id" exact component={User} />
                  <SecureRoute path="/groups" exact component={Groups} />
                  <SecureRoute path="/groups/:id/invitecodes" exact component={GroupInviteCodes} />
                  <SecureRoute path="/groups/:id" exact component={Group} />
                  <SecureRoute path="/groups/:groupId/users/:id" exact component={User} />
                  <SecureRoute path="/events" exact component={Events} />
                  <SecureRoute path="/events/:id" exact component={Event} />
                  <SecureRoute path="/semester" exact component={Semester} />
                  <SecureRoute path="/reports" exact component={Reports} />
                </Grid>
              </Grid>
              <Route path="/implicit/callback" component={LoginCallback} />
            </ThemeProvider>
          </Switch>
        </Security>
      </Router>
    </div>
  );
}

export default App;
