import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ConfirmationModal = (props) => {
  const {
    onConfirm,
    onCancel,
    show,
    headerText,
    bodyText,
    yesCta,
    noCta,
    showNo
  } = props;

  return (
    <Dialog open={show}>
      {headerText && <DialogTitle>{headerText}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="d-flex">
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            onClick={onConfirm}
          >
            {yesCta || 'Yes'}
          </Button>
          {showNo && <Button variant="outlined" color="primary" onClick={onCancel}>
            {noCta || 'No'}
          </Button>}
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.defaultProps = {
  showNo: true
}

export default ConfirmationModal;
