import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { format } from 'date-fns';
import { getEventsAction } from '../store/actions/event.actions';
// import './EventSearch.scss';

const HARD_LIMIT = 10;

const renderDate = (d) => format(new Date(d), 'MM/dd/yyyy hh:mm aa');

const EventSearch = (props) => {
  const { selectEvent, show, onHide, groupId } = props;
  const events = useSelector((state) => state.event.events);
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const search = async () => {
    setIsSearching(true);
    const filter = {};
    if (title) {
      filter.name = `${title}`;
    }
    if (groupId) {
      filter.groupId = groupId
    }
    await dispatch(
      getEventsAction({
        filter,
        limit: HARD_LIMIT,
      })
    );
    setHasSearched(true);
    setIsSearching(false);
  };

  return (
    <Dialog maxWidth="lg" className="user-search" open={show} onClose={onHide}>
      <DialogTitle disableTypography className="d-flex align-items-center">
        <Typography variant="h6" className="flex-grow-1">
          User Search
        </Typography>
        <IconButton onClick={onHide}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          label="Event title"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          fullWidth
        />
        {hasSearched && events.data && (
          <List>
            {events.data.map((event) => (
              <ListItem key={event.id}>
                <ListItemIcon className="cursor-pointer" onClick={() => selectEvent(event)}>
                  <Add color="primary" />
                </ListItemIcon>
                <ListItemText className="mr-1" style={{width: '30rem'}}>{event.name}</ListItemText>
                <ListItemText className="mr-1">{event.groupName}</ListItemText>
                <ListItemText>{renderDate(event.startTime)}-{renderDate(event.endTime)}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={search}>
          {isSearching ? <CircularProgress color="secondary" /> : 'Search'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventSearch;
