export const API_ERROR = 'API_ERROR';
export const CLEAR_API_ERROR = 'CLEAR_API_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const SYNC_USERS = 'SYNC_USERS';
export const GET_POINTS_SUMMARY = 'GET_POINTS_SUMMARY';
export const GET_GROUP_POINTS_SUMMARY = 'GET_GROUP_POINTS_SUMMARY';

export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_LOOKUP = 'GET_GROUPS_LOOKUP';
export const GET_GROUP = 'GET_GROUP';
export const GET_GROUP_INVITE_CODES = 'GET_GROUP_INVITE_CODES';
export const CREATE_GROUP_INVITE_CODE = 'CREATE_GROUP_INVITE_CODE';
export const UPDATE_GROUP_INVITE_CODE = 'UPDATE_GROUP_INVITE_CODE';
export const SELECT_GROUP_INVITE_CODE = 'SELECT_GROUP_INVITE_CODE';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS';
export const GET_POTENTIAL_MEMBERS = 'GET_POTENTIAL_MEMBERS';
export const CREATE_GROUP_MEMBER = 'CREATE_GROUP_MEMBER';

export const CREATE_EVENT = 'CREATE_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const CLONE_EVENT = 'CLONE_EVENT';

export const GET_IMAGE_UPLOAD_TOKEN = 'GET_IMAGE_UPLOAD_TOKEN';

export const GET_ERRORS = 'GET_ERRORS';
export const GET_DETAILED_ERRORS = 'GET_DETAILED_ERRORS';

export const SET_AUTH_USER = 'SET_AUTH_USER';

export const SET_SEMESTER_DATA = 'SET_SEMESTER_DATA';
