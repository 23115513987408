import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableBody,
  TablePagination,
  Paper,
  Button,
  Table,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Switch,
  LinearProgress,
} from '@material-ui/core';
import {
  getGroupInviteCodesAction,
  getGroupAction,
  createGroupInviteCodeAction,
  updateGroupInviteCodeAction,
  selectGroupInviteCode
} from '../store/actions/group.actions';
import { PAGINATION_OPTIONS } from '../constants';
import { format } from 'date-fns';
import ConfirmationModal from '../components/ConfirmationModal';
import './GroupInviteCodes.scss';



const GroupInviteCodes = () => {
  const INVITE_CODE_FIELDS = [
    {
      display: 'Invite Code',
      render: (g) => g.inviteCode,
      sortField: 'inviteCode',
    },
    {
      display: 'Active',
      render: (g) => <FormGroup>
        <FormControlLabel
          control={<Switch checked={g.active} onClick={(e) => e.stopPropagation()} onChange={(e) => handleActiveChange(g, e.target.checked)} name="active" />}
        />
      </FormGroup>,
      sortField: 'active',
    },
    {
      display: 'Created Date',
      render: (g) => format(new Date(g.createdDate), 'M/dd/yyyy hh:mm a'),
      sortField: 'createdDate',
    }
  ];

  const params = useParams();
  const groupInviteCodes = useSelector((state) => state.group.groupInviteCodes);
  const currentInviteCode = useSelector((state) => state.group.groupInviteCode ? state.group.groupInviteCode.inviteCode : '');
  const group = useSelector((state) => state.group.group);
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showInviteCodeResultModal, setShowInviteCodeResultModal] = useState(false);

  useEffect(() => {
    const loadGroupInviteCodes = async () => {
      setIsLoading(true);
      await dispatch(getGroupAction(params.id))
      await dispatch(getGroupInviteCodesAction(params.id));
      setIsLoading(false);
    };
    loadGroupInviteCodes();
  }, [dispatch, params.id]);

  useEffect(() => {
    const loadGroupInviteCodes = async () => {
      let ordering;
      if (sortField) {
        ordering = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
      }
      await dispatch(
        getGroupInviteCodesAction(params.id, {
          skip: countPerPage * pageNumber,
          limit: countPerPage,
          ordering,
          includeInactive: showInactive
        })
      );
    };
    loadGroupInviteCodes();
  }, [dispatch, params.id, sortField, sortDirection, countPerPage, pageNumber, showInactive]);


  const addGroupInviteCode = async () => {
    const inviteCode = await dispatch(createGroupInviteCodeAction(params.id));
    if (inviteCode) {
      setShowAddModal(false);
      setShowInviteCodeResultModal(true);
    }
    await dispatch(getGroupInviteCodesAction(params.id, {
        includeInactive: showInactive
    }));
  }

  const handleActiveChange = async (inviteCode, active) => {
    const inviteCodeToUpdate =
    {
      ...inviteCode,
      active
    };

    const updatedInviteCode = await dispatch(updateGroupInviteCodeAction(params.id, inviteCodeToUpdate));

    if (updatedInviteCode) {
      await dispatch(getGroupInviteCodesAction(params.id, {
        includeInactive: showInactive
      }));
    }
  }

  const showInviteCode = async (inviteCode) => {
    dispatch(selectGroupInviteCode(inviteCode));
    setShowInviteCodeResultModal(true);
  }

  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
  };
  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
  };

  const handleShowInactiveChanged = (newShowInactive) => {
    setShowInactive(newShowInactive);
  }

  return (
    <div className="groups">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Group Invite Codes for: {group ? group.name : ''}</span>

      </h2>
      <div className="d-flex mt-2 mb-2">
        <Button
          variant="outlined"
          color="primary"
          className="px-1"
          onClick={() => setShowAddModal(true)}
        >
          <Add />
          Add
        </Button>
        <div className="flex-grow-1" /> 
        <FormControlLabel
        control={
          <Checkbox
            checked={showInactive}
            onChange={(e) => handleShowInactiveChanged(e.target.checked)}
            name="showInactive"
            color="primary"
          />
        }
        label="Show Inactive Codes"
      />
      </div>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!groupInviteCodes && (
        <>
          <TableContainer component={Paper}>
            <Table size="small" padding="none" className="table">
              <TableHead className="bg-dark">
                <TableRow>
                  {INVITE_CODE_FIELDS.map((field) => {
                    return (
                      <TableCell key={field.display} align="left">
                        <TableSortLabel
                          active={field.sortField === sortField}
                          className="text-white"
                          direction={
                            field.sortField === sortField
                              ? sortDirection
                              : 'desc'
                          }
                          hideSortIcon={!field.sortField}
                          onClick={() => updateSort(field.sortField)}
                        >
                          {field.display}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupInviteCodes.data.map((inviteCode) => (
                  <TableRow
                    key={inviteCode.id}
                    hover
                    className="cursor-pointer"
                    onClick={() => showInviteCode(inviteCode)}
                  >
                    {INVITE_CODE_FIELDS.map((field) => (
                      <TableCell key={field.display} align="left">
                        <div className="ellipsis">{field.render(inviteCode)}</div>

                      </TableCell>
                    ))}
                    <TableCell align="center">

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={countPerPage}
            component="div"
            count={groupInviteCodes.totalCount}
            page={groupInviteCodes.currentPage - 1}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
      <ConfirmationModal
        show={showAddModal}
        onConfirm={addGroupInviteCode}
        onCancel={() => setShowAddModal(false)}
        headerText="Add Group Invite Code"
        bodyText="Add a new invite code to this group?"
      />
      <ConfirmationModal
        show={showInviteCodeResultModal}
        onConfirm={() => setShowInviteCodeResultModal(false)}
        headerText="Invite Code"
        bodyText={`Your Invite Code is: ${currentInviteCode}`}
        showNo={false}
        yesCta="OK"
      />
    </div>
  );
};

export default GroupInviteCodes;
